import { Colors } from '../consts/colors';

export const MuiStepLabel = (brandPrimary: string) => ({
  root: {
    '&.Mui-disabled': {
      '& .MuiStepLabel-label': {
        color: `${Colors.lightGrey} !important`,
      },
    },
  },
  label: {
    fontSize: '18px',
    color: Colors.black,
    fontWeight: 600,
    '&.MuiStepLabel-active': {
      color: brandPrimary,
      fontWeight: 600,
    },
    '&.MuiStepLabel-completed': {
      color: Colors.green,
      fontWeight: 600,
    },
  },
  labelContainer: {
    marginLeft: 12,
  },
});
