import { Colors } from '../consts/colors';

export const MuiDialog = (brandPrimary: string) => ({
  root: {},
  paperWidthSm: {
    maxWidth: '100%',
  },
  paper: {
    border: `1px ${brandPrimary} solid`,
    borderRadius: 10,
  },
});
