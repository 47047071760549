import { MobileFontSizes, TabletDesktopFontSizes } from '../consts/FontSizes';
import { DesktopFontWeights, MobileFontWeights, TabletFontWeights } from '../consts/FontWeights';
import { WindowSizeValues, WindowSizeVariants } from '../types/WindowSizeTypes';
import { TextVariantValues } from '../types/TextTypes';
import { Colors, IColors } from '../consts/colors';

export function getFontSize(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping =
    windowSize === WindowSizeVariants.mobile ? MobileFontSizes : TabletDesktopFontSizes;
  return sizesMapping[variant];
}

export function getFontColor(color: IColors, primaryColor: string): string {
  return color === 'purple' ? primaryColor : !!color ? Colors[color] : Colors.black;
}

export function getFontWeight(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping =
    windowSize === WindowSizeVariants.mobile
      ? MobileFontWeights
      : windowSize === WindowSizeVariants.tablet
      ? TabletFontWeights
      : DesktopFontWeights;
  return sizesMapping[variant];
}

export const addPlural = (entityAmount: number): string => {
  if (entityAmount > 1 || entityAmount === 0) {
    return 's';
  } else {
    return '';
  }
};
