import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { isAfter, parse } from 'date-fns';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { IStoreTypes } from '../../store/storeTypes';
import { ActionsEngine } from '../Actions/ActionsEngine';

const useStyles = makeStyles({
  scrollable: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: `calc(100vh - 640px)`,
    border: `1px solid ${Colors.backgroundDarkPurple}`,
    borderRadius: 10,
    paddingBottom: 20,
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.purple,
      borderRadius: 6,
      border: `2px solid transparent`,
    },
  },
  defaultMargin: {
    paddingTop: 10,
  },
});

interface IActionsSection {
  scrollable?: boolean;
  deletable?: boolean;
}

export const ActionsSection: React.FC<IActionsSection> = ({ scrollable, deletable }) => {
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const actions = useSelector((state: IStoreTypes) => state.user?.actions)
    ?.filter((a) => a.roleId === sessionRoleId)
    ?.sort((a, b) =>
      isAfter(
        parse(a.dueDate, 'dd/MM/yyyy', new Date()),
        parse(b.dueDate, 'dd/MM/yyyy', new Date()),
      )
        ? 1
        : -1,
    );
  const styles = useStyles();

  return (
    <Grid container className={scrollable ? styles.scrollable : styles.defaultMargin} item>
      {!actions || actions?.length === 0 ? (
        <Grid container item justify="center" alignItems="center">
          <Text variant="sm" marginTop={10} align="center" fontWeight={500}>
            You have no notifications to action &#127881;
          </Text>
        </Grid>
      ) : (
        <Grid container justify="center">
          <ActionsEngine actions={actions} deletable={deletable} />
        </Grid>
      )}
    </Grid>
  );
};
