import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Add from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Apps from '@material-ui/icons/Apps';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Check from '@material-ui/icons/Check';
import Delete from '@material-ui/icons/Delete';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import ExitToApp from '@material-ui/icons/ExitToApp';
import EventNote from '@material-ui/icons/EventNote';
import IconButton from '@material-ui/core/IconButton';
import { Colors } from '../../consts/colors';
import { getFontSize } from '../../utils/TextUtils';
import { WindowSizeVariants } from '../../types/WindowSizeTypes';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  roundedButton: {
    width: (props: { mobile: boolean }) => (props.mobile ? 30 : 48),
    height: (props: { mobile: boolean }) => (props.mobile ? 30 : 48),
    minWidth: (props: { mobile: boolean }) => (props.mobile ? 30 : 48),
    padding: 0,
    borderRadius: '50%',
    backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
    borderColor: (props: { primaryColor: string }) => props.primaryColor,
    '&:hover': {
      backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
      color: Colors.white,
      borderColor: (props: { primaryColor: string }) => props.primaryColor,
    },
  },
  prevStep: {
    maxWidth: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    minWidth: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    height: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    paddingLeft: 0,
    paddingRight: 0,
  },
  nextStep: {
    maxWidth: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    minWidth: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    height: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
  },
  saveModuleBtn: {
    maxWidth: (props: { mobile: boolean }) => (props.mobile ? 50 : 100),
    minWidth: (props: { mobile: boolean }) => (props.mobile ? 50 : 100),
    height: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 35 : 70),
  },
  startIcon: {
    color: (props: { primaryColor: string }) => props.primaryColor,
  },
  endIcon: {
    color: Colors.white,
  },
  minutes: {
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  negativeEndIcon: {
    color: Colors.red,
  },
  negative: {
    borderColor: Colors.red,
    color: Colors.red,
    fontWeight: 600,
    fontSize: (props: { mobile: boolean }) => (props.mobile ? 14 : 20),
  },
  appsIcon: {
    color: Colors.white,
  },
  checkIcon: {
    fontSize: 20,
  },
  navSearch: {
    borderRadius: '8px !important',
    fontSize: 13,
    fontWeight: 600,
    marginTop: 20,
    width: 'fit-content',
  },
  navSearchLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  letterSearch: {
    background: (props: { primaryColor: string }) => props.primaryColor,
    '&:hover': {
      background: (props: { primaryColor: string }) => props.primaryColor,
    },
  },
  letterSearchLabel: {
    fontSize: 18,
    color: Colors.white,
  },
  large: {
    padding: '45px 12px',
  },
  buttonDefault: {
    fontSize: (props: { fontSize: number }) => props.fontSize,
  },
  action: {
    height: (props: { mobile: boolean }) => (props.mobile ? 42 : 64),
    padding: (props: { mobile: boolean }) => (props.mobile ? 12 : 18),
    background: (props: { primaryColor: string }) => props.primaryColor,
    '&:hover': {
      background: (props: { primaryColor: string }) => props.primaryColor,
    },
  },
  actionLabel: {
    fontSize: (props: { mobile: boolean }) => (props.mobile ? 14 : 20),
    color: Colors.white,
  },
  delete: {
    border: `2px solid ${Colors.red}`,
    height: (props: { mobile: boolean }) => (props.mobile ? 42 : 64),
    padding: (props: { mobile: boolean; small: boolean }) =>
      props.mobile ? (props.small ? 8 : 12) : props.small ? 12 : 18,
    background: Colors.white,
    '&:hover': {
      background: Colors.white,
      border: `2px solid ${Colors.red}`,
    },
  },
  deleteLabel: {
    fontSize: (props: { mobile: boolean }) => (props.mobile ? 14 : 20),
    color: Colors.red,
  },
  reject: {
    border: `2px solid ${Colors.red}`,
    height: (props: { mobile: boolean }) => (props.mobile ? 42 : 64),
    padding: (props: { mobile: boolean }) => (props.mobile ? 12 : 18),
    background: Colors.red,
    '&:hover': {
      border: `2px solid ${Colors.red}`,
      background: Colors.red,
    },
  },
  rejectLabel: {
    fontSize: (props: { mobile: boolean }) => (props.mobile ? 14 : 20),
    color: Colors.white,
  },
  transparent: {
    background: Colors.transparent,
  },
});

interface IActionButton extends IBrancherButton {}

interface IBrancherButton extends ButtonProps {
  fontSize?: number;
  loading?: boolean;
}

export const BrancherButton = (props: IBrancherButton) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const size = mobile ? WindowSizeVariants.mobile : WindowSizeVariants.tabletDesktop;
  const fontSize = props?.fontSize ?? props.size === 'small' ? '18px' : getFontSize(size, 'md');
  const styles = useStyles({ ...props, fontSize, primaryColor });
  const { children, loading, ...other } = props;
  return (
    <Button
      variant="outlined"
      size="large"
      classes={{ label: styles.buttonDefault }}
      disabled={loading}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const LargeButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton className={styles.large} color="secondary" {...other}>
      {children}
    </BrancherButton>
  );
};

export const NegativeButton = (props: ButtonProps) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton
      className={styles.negative}
      startIcon={<Close className={styles.negativeEndIcon} />}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const ErrorButton = (props: ButtonProps) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton className={styles.negative} {...other}>
      {children}
    </BrancherButton>
  );
};

export const EditLinkButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton color="primary" startIcon={<Edit className={styles.endIcon} />} {...other}>
      {children}
    </BrancherButton>
  );
};

export const CancelButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, 'aria-label': ariaLabel, ...other } = props;
  return (
    <BrancherButton
      className={styles.negative}
      aria-label={ariaLabel ?? 'cancel'}
      variant="outlined"
      {...other}
    >
      {children ?? 'Cancel'}
    </BrancherButton>
  );
};

export const NextButton = (props: IActionButton) => {
  const primaryColor =
    useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor) ?? Colors.purple;
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton
      color="primary"
      endIcon={
        <ArrowForward
          className={
            props.color === 'secondary' || props?.variant === 'text'
              ? styles.startIcon
              : styles.endIcon
          }
        />
      }
      aria-label="next"
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

interface IPrevButton extends IBrancherButton {
  transparent?: boolean;
}

export const PrevButton = (props: IPrevButton) => {
  const { children, transparent, ...other } = props;
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  return (
    <BrancherButton
      color="secondary"
      startIcon={<ArrowBack className={styles.startIcon} />}
      className={transparent && styles.transparent}
      aria-label="previous"
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const ChevronButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton
      color="primary"
      variant="text"
      endIcon={
        <ChevronRight className={props.color === 'secondary' ? styles.startIcon : styles.endIcon} />
      }
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const SavedCheckedButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton color="secondary" endIcon={<Check className={styles.checkIcon} />} {...other}>
      Saved
    </BrancherButton>
  );
};

export const EditButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <BrancherButton
      variant="text"
      color="primary"
      startIcon={<Edit className={styles.startIcon} />}
      {...other}
    >
      Edit
    </BrancherButton>
  );
};

export const SearchNavigationButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<Apps className={styles.appsIcon} />}
      classes={{ root: styles.navSearch, label: styles.navSearchLabel }}
      {...other}
    >
      {children}
    </Button>
  );
};

export const SearchLetterButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <Button
      variant="outlined"
      classes={{ root: styles.letterSearch, label: styles.letterSearchLabel }}
      {...other}
    >
      {children}
    </Button>
  );
};

export const CheckAnswerButton = (props: ButtonProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { children, ...other } = props;
  return mobile ? (
    <BrancherButton color="primary" fullWidth {...other}>
      Check answer
    </BrancherButton>
  ) : (
    <Box display="flex" alignSelf="flex-end" maxWidth="fit-content">
      <BrancherButton color="primary" {...other}>
        Check answer
      </BrancherButton>
    </Box>
  );
};

interface IStepButtons extends ButtonProps {
  mobile: boolean;
}

export const NextStepButton = (props: IStepButtons) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, mobile, ...other } = props;
  return (
    <Button
      variant="outlined"
      color="primary"
      aria-label="next step"
      className={styles.nextStep}
      {...other}
    >
      {children}
    </Button>
  );
};

export const SaveModuleButton = (props: IStepButtons) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, mobile, ...other } = props;
  return (
    <BrancherButton
      variant="outlined"
      color="secondary"
      aria-label="save module"
      className={styles.saveModuleBtn}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const PrevStepButton = (props: IStepButtons) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, mobile, ...other } = props;
  return (
    <BrancherButton
      variant="outlined"
      color="secondary"
      aria-label="previous step"
      className={styles.prevStep}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const ActionButton = (props: IActionButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  const { children, loading, ...other } = props;
  return (
    <Button
      variant="outlined"
      classes={{ root: props.size !== 'small' ? styles.action : '', label: styles.actionLabel }}
      disabled={loading || other?.disabled}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

interface IMeetingMinutesButton extends IActionButton {
  mini: boolean;
}

export const MeetingMinutesButton = (props: IMeetingMinutesButton) => {
  const { mini, ...other } = props;
  return mini ? (
    <IconButton onClick={other.onClick}>
      <EventNote />
    </IconButton>
  ) : (
    <ActionButton
      color="primary"
      size={mini ? 'small' : 'medium'}
      endIcon={<EventNote color="action" />}
      {...other}
    >
      {!mini ? 'Minutes' : ''}
    </ActionButton>
  );
};

export const AddMeetingButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <ActionButton
      color="primary"
      aria-label="add meeting"
      startIcon={<Add className={styles.appsIcon} />}
      {...other}
    >
      {children}
    </ActionButton>
  );
};

export const AddButton = (props: ButtonProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { children, ...other } = props;
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <BrancherButton className={styles.roundedButton} aria-label="add" {...other}>
      <Add className={styles.appsIcon} />
    </BrancherButton>
  );
};

export const ViewButton = (props: ButtonProps) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <ActionButton
      color="primary"
      aria-label="view"
      endIcon={<ArrowForward className={styles.appsIcon} />}
      {...other}
    >
      {children}
    </ActionButton>
  );
};

export const CalendarButton = (props: IActionButton) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { children, ...other } = props;
  return (
    <ActionButton
      color="primary"
      aria-label="save meeting"
      startIcon={<EventIcon className={styles.appsIcon} />}
      {...other}
    >
      {children}
    </ActionButton>
  );
};

interface ICreateDeleteButton extends ButtonProps {
  loading?: boolean;
}

export const DeleteButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <ActionButton
      color="secondary"
      aria-label="delete"
      classes={{ root: styles.delete, label: styles.deleteLabel }}
      startIcon={<Delete className={styles.negativeEndIcon} />}
      {...other}
    >
      {loading ? <CircularProgress className={styles.negativeEndIcon} size={48} /> : 'Delete'}
    </ActionButton>
  );
};

export const LogoutButton = (props: ButtonProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <BrancherButton
      color={props.color ?? 'secondary'}
      variant="text"
      size="small"
      aria-label="logout"
      endIcon={
        <ExitToApp className={props?.color === 'primary' ? styles.startIcon : styles.endIcon} />
      }
      {...props}
    >
      Log out
    </BrancherButton>
  );
};

export const CreateButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <BrancherButton
      color="secondary"
      aria-label={other?.['aria-label'] ?? 'save'}
      startIcon={<Save className={styles.startIcon} />}
      disabled={loading}
      {...other}
    >
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children ?? 'Save'}
    </BrancherButton>
  );
};

export const ResetButton = (props: IActionButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, small: other?.size === 'small', primaryColor });
  return (
    <NegativeButton aria-label={other?.['aria-label'] ?? 'reset'} disabled={loading} {...other}>
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children ?? 'Reset'}
    </NegativeButton>
  );
};

export const UpdateButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <BrancherButton
      color="secondary"
      aria-label={other?.['aria-label'] ?? 'update'}
      startIcon={<Edit className={styles.startIcon} />}
      disabled={loading}
      {...other}
    >
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children ?? 'Edit'}
    </BrancherButton>
  );
};

export const SaveButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <BrancherButton color="secondary" aria-label="save" {...other}>
      {children ?? 'Save'}
    </BrancherButton>
  );
};

export const RejectButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <ActionButton
      color="secondary"
      aria-label="reject"
      classes={{ root: styles.reject, label: styles.rejectLabel }}
      startIcon={<Close className={styles.endIcon} />}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children ?? 'Reject'}
    </ActionButton>
  );
};

export const AcceptButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, primaryColor });
  return (
    <ActionButton
      variant="contained"
      aria-label="accept"
      startIcon={<Check className={styles.endIcon} />}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children ?? 'Accept'}
    </ActionButton>
  );
};
