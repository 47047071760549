import { Colors } from '../consts/colors';

export const MuiLinearProgress = (brandPrimary: string) => ({
  root: {
    borderRadius: 4,
    height: 8,
    maxWidth: `800px !important`,
  },
  colorPrimary: {
    backgroundColor: Colors.lightGrey,
  },
  barColorPrimary: {
    backgroundColor: brandPrimary,
  },
});
