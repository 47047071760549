import { Colors, IColors } from '../../consts/colors';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Button from '@material-ui/core/Button';

interface ISurveyEmojiButton {
  ratingIndex: number;
  selectedIndex: number;
  setRating: (rating: number) => void;
  color: IColors;
}

const useStyles = makeStyles({
  iconButton: {
    border: (props: { color: string; isSelected: boolean }) =>
      props.isSelected ? `3px solid ${Colors[props.color]}` : '',
    fontSize: (props: { color: string; isSelected: boolean }) => (props.isSelected ? 80 : 48),
    color: (props: { color: string; isSelected: boolean }) => Colors[props.color],
  },
});

export const SurveyEmojiButton: React.FC<ISurveyEmojiButton> = ({
  ratingIndex,
  setRating,
  selectedIndex,
  children,
  color,
}) => {
  const isSelected = selectedIndex === ratingIndex;
  const styles = useStyles({ isSelected, color });

  return (
    <Button onClick={() => setRating(ratingIndex)} className={styles.iconButton}>
      {children}
    </Button>
  );
};
