import { Colors } from '../consts/colors';

export const MuiCheckbox = (brandPrimary: string) => ({
  root: {
    color: Colors.lightGrey,
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: brandPrimary,
      },
    },
  },
});
