import { Colors } from '../consts/colors';

export const MuiAlert = {
  outlinedInfo: {
    alignItems: 'center',
    backgroundColor: Colors.backgroundDarkerPurple,
    borderColor: Colors.darkBlue,
  },
  standardInfo: {
    alignItems: 'center',
    backgroundColor: Colors.backgroundDarkerPurple,
    border: `1px solid ${Colors.darkBlue}`,
  },
};
