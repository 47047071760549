import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Colors } from '../consts/colors';
import { IGeneralFormConfig } from './General/GeneralFormConfig';
import { IFinalisingFormConfig } from './Finalising/FinalisingFormConfig';
import { Text } from '../Components/General/Text';
import { SaveButton } from '../Components/InputFields/BrancherButton';
import { IValuesFormConfig } from './Values/ValuesFormConfig';
import { IPersonalityFormConfig } from './Personality/PersonalityFormConfig';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../store/storeTypes';

export enum EApplicationFormSections {
  QUALIFYING = 'qualifying',
  GENERAL = 'general',
  VALUES = 'values',
  PERSONALITY = 'personality',
  FINALISING = 'finalising',
}

export const FormPaneHeight = 100;

const useStyles = makeStyles({
  root: {
    '& .MuiStepper-root': {
      width: '-webkit-fill-available',
      background: Colors.transparent,
      justifyContent: 'center',
    },
  },
  activeStep: {
    color: (props: { primaryColor: string }) => props.primaryColor,
    fontSize: 15,
    marginLeft: 5,
  },
  mobileStepper: {
    '& .MuiMobileStepper-dots': {
      width: 180,
      justifyContent: 'space-between',
    },
    '& .MuiMobileStepper-dot': {
      backgroundColor: Colors.green,
    },
    '& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot': {
      backgroundColor: Colors.disabledGrey,
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
    },
  },
  notActive: {
    color: Colors.black,
    fontSize: 15,
    marginLeft: 5,
  },
  completed: {
    color: Colors.green,
    fontSize: 18,
    marginLeft: 5,
  },
  progressBar: {
    marginTop: `calc(${FormPaneHeight}px + 10px)`,
  },
  stepperPane: {
    position: 'fixed',
    top: 0,
    paddingLeft: 10,
    paddingRight: 10,
    height: 100,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    minWidth: '100%',
    zIndex: 100,
    boxShadow: `0 2px 2px -2px ${Colors.darkGrey};`,
  },
});

interface IFormStepper {
  step: number;
  editApplicationForm: boolean;
  submittedFinalisingForm: boolean;
  saveFormData: (a: boolean) => void;
  generalQuestions: IGeneralFormConfig[];
  finalisingQuestions?: IFinalisingFormConfig[];
  valuesQuestions?: IValuesFormConfig[];
  personalityQuestions?: IPersonalityFormConfig[];
}

export enum EFormStepperSections {
  GENERAL = 'General',
  VALUES = 'Values',
  PERSONALITY = 'Personality',
  REPORTING = 'Reporting',
}

export const FormStepper: React.FC<IFormStepper> = ({
  step,
  generalQuestions,
  submittedFinalisingForm,
  finalisingQuestions,
  personalityQuestions,
  valuesQuestions,
  editApplicationForm,
  saveFormData,
}) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ primaryColor });

  const generalStepsLength = generalQuestions.length;
  const valuesConfigLength = valuesQuestions.length;
  const generalValuesLength = generalStepsLength + valuesConfigLength;
  const personalityConfigLength = personalityQuestions.length;
  const generalValuesPersonalityLength =
    generalStepsLength + valuesConfigLength + personalityConfigLength;
  const finalisingQuestionsLength = finalisingQuestions.length;
  const generalValuesPersonalityFinalisingLength =
    generalStepsLength + valuesConfigLength + personalityConfigLength + finalisingQuestionsLength;

  const allQuestions = [
    ...generalQuestions,
    ...valuesQuestions,
    ...personalityQuestions,
    ...finalisingQuestions,
  ];
  const sections: Set<EFormStepperSections> = new Set();

  let sectionQuestions = {};
  allQuestions.forEach((question, index) => {
    if (!!question.section) {
      if (sections.has(question.section)) {
        sectionQuestions[question.section].push(question);
      } else {
        sections.add(question.section);
        sectionQuestions[question.section] = [question];
      }
    } else {
      if (index + 1 <= generalStepsLength) {
        if (!sectionQuestions?.[EFormStepperSections.GENERAL]) {
          sectionQuestions[EFormStepperSections.GENERAL] = [];
        }
        sectionQuestions[EFormStepperSections.GENERAL]?.push(question);
      } else if (index + 1 > generalStepsLength && index + 1 <= generalValuesLength) {
        if (!sectionQuestions?.[EFormStepperSections.VALUES]) {
          sectionQuestions[EFormStepperSections.VALUES] = [];
        }
        sectionQuestions[EFormStepperSections.VALUES]?.push(question);
      } else if (index + 1 > generalValuesLength && index + 1 <= generalValuesPersonalityLength) {
        if (!sectionQuestions?.[EFormStepperSections.PERSONALITY]) {
          sectionQuestions[EFormStepperSections.PERSONALITY] = [];
        }
        sectionQuestions[EFormStepperSections.PERSONALITY]?.push(question);
      } else if (
        index + 1 > generalValuesPersonalityLength &&
        index + 1 <= generalValuesPersonalityFinalisingLength
      ) {
        if (!sectionQuestions?.[EFormStepperSections.REPORTING]) {
          sectionQuestions[EFormStepperSections.REPORTING] = [];
        }
        sectionQuestions[EFormStepperSections.REPORTING]?.push(question);
      }
    }
  });

  const hasConfiguredSections = sections.size > 0;

  const currQuestion = allQuestions[step];
  let sectionQuestionIndex = 0;
  let questionIndex = 0;
  allQuestions.forEach((a, index) => {
    const isNotStepped = step.toString().indexOf('.5') === -1;
    if (hasConfiguredSections) {
      if (a?.section === currQuestion?.section && index <= step) {
        if (isNotStepped) {
          sectionQuestionIndex++;
        }
        // Values questions don't have names - but the index finding is enough to know what valuesIndex we are looking at
        if (
          !!currQuestion &&
          (('name' in a && 'name' in currQuestion && a.name === currQuestion.name) ||
            a.section === EFormStepperSections.VALUES)
        ) {
          questionIndex = sectionQuestionIndex;
        }
      }
    } else {
      if (step + 1 <= generalStepsLength && index + 1 <= generalStepsLength) {
        if (isNotStepped) {
          sectionQuestionIndex++;
        }
        if (index === step) {
          questionIndex = sectionQuestionIndex;
        }
      } else if (
        step + 1 <= generalValuesLength &&
        index + 1 > generalStepsLength &&
        index + 1 <= generalValuesLength
      ) {
        if (isNotStepped) {
          sectionQuestionIndex++;
        }
        if (index === step) {
          questionIndex = sectionQuestionIndex;
        }
      } else if (
        step + 1 <= generalValuesPersonalityLength &&
        index + 1 > generalValuesLength &&
        index + 1 <= generalValuesPersonalityLength
      ) {
        if (isNotStepped) {
          sectionQuestionIndex++;
        }
        if (index === step) {
          questionIndex = sectionQuestionIndex;
        }
      } else if (
        step + 1 <= generalValuesPersonalityFinalisingLength &&
        index + 1 > generalValuesPersonalityLength &&
        index + 1 <= generalValuesPersonalityFinalisingLength
      ) {
        if (isNotStepped) {
          sectionQuestionIndex++;
        }
        if (index === step) {
          questionIndex = sectionQuestionIndex;
        }
      }
    }
  });

  let activeSection = 0;
  if (!hasConfiguredSections) {
    sections.add(EFormStepperSections.GENERAL);
    sections.add(EFormStepperSections.VALUES);
    sections.add(EFormStepperSections.PERSONALITY);
    sections.add(EFormStepperSections.REPORTING);
    step = step.toString().indexOf('.5') !== -1 ? step + 0.5 : step;
    activeSection =
      step < generalStepsLength
        ? 0
        : step < generalValuesLength
        ? 1
        : step < generalValuesPersonalityLength
        ? 2
        : 3;
  } else {
    activeSection = Array.from(sections).findIndex((f) => f === allQuestions[step]?.section);
    if (activeSection === -1) {
      const valuesIndex = allQuestions.findIndex((f) => f.section === EFormStepperSections.VALUES);
      const personalityIndex = allQuestions.findIndex(
        (f) => f.section === EFormStepperSections.PERSONALITY,
      );
      if (valuesIndex === 0.5 + step) {
        activeSection = Array.from(sections).findIndex((f) => f === EFormStepperSections.VALUES);
      } else if (personalityIndex === 0.5 + step) {
        activeSection = Array.from(sections).findIndex(
          (f) => f === EFormStepperSections.PERSONALITY,
        );
      }
    }
  }
  const adjustedSections: string[] = Array.from(sections);
  const arraySection = adjustedSections[activeSection];
  const currentSectionQuestionsAmount = sectionQuestions[arraySection]?.length;

  return (
    <>
      <Grid item container alignItems="center" xs={12} className={styles.stepperPane}>
        <Grid item xs={2}>
          <SaveButton
            endIcon={<ExitToApp />}
            fontSize={mobile ? 12 : 20}
            size={mobile ? 'small' : 'large'}
            disabled={step < 1}
            onClick={() => saveFormData(submittedFinalisingForm && !editApplicationForm)}
          >
            {editApplicationForm ? `Save` : `Save & Quit`}
          </SaveButton>
        </Grid>
        <Grid item xs={8} container className={styles.root}>
          {mobile ? (
            <Grid container item justify="center" alignItems="center">
              <Grid item>
                <MobileStepper
                  className={styles.mobileStepper}
                  variant="dots"
                  steps={adjustedSections.length}
                  position="static"
                  activeStep={activeSection}
                  backButton={null}
                  nextButton={null}
                />
              </Grid>
              <Text variant="lg" marginLeft={50} fontWeight={600} color="purple">
                {arraySection}
              </Text>
            </Grid>
          ) : (
            <Stepper activeStep={activeSection} orientation="horizontal">
              {adjustedSections.map((section, index) => (
                <Step key={section}>
                  <StepLabel
                    active={activeSection === index}
                    icon={
                      activeSection === index ? (
                        <RadioButtonUnchecked className={styles.activeStep} />
                      ) : activeSection > index ? (
                        <CheckCircle className={styles.completed} />
                      ) : (
                        <RadioButtonUnchecked className={styles.notActive} />
                      )
                    }
                  >
                    {section}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justify="flex-start"
        xs={11}
        md={9}
        className={styles.progressBar}
      >
        <Grid item container alignItems="center" justify="flex-start" xs={6}>
          <Grid item xs={3} md={2}>
            <Text variant="sm" color="purple">
              {questionIndex}/{currentSectionQuestionsAmount}
            </Text>
          </Grid>
          <Grid item xs={8} md={9}>
            <LinearProgress
              value={questionIndex * (100 / currentSectionQuestionsAmount)}
              valueBuffer={100}
              color="primary"
              variant="determinate"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
