import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Colors } from '../../consts/colors';
import { MobileFontSizes } from '../../consts/FontSizes';
import { Text } from '../General/Text';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

export interface IBrancherSegment {
  value: string | number;
  label: string;
  fullWidth?: boolean;
}

interface ISegmentedControl {
  options?: IBrancherSegment[];
  fullWidth?: boolean;
  question?: string;
}

interface ISegmentedControlGroupStyles extends ISegmentedControl {
  valueLength?: number;
  mobile?: boolean;
}

interface ISegmentedControlGroup extends ISegmentedControl, Pick<IBrancherSegment, 'value'> {
  updateValue: (a: string | string[] | number | number[]) => void;
}

const useStyles = makeStyles({
  tbroot: {
    backgroundColor: Colors.white,
    '&$selected': {
      backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
      color: Colors.white,
      '&:hover': {
        backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
        color: Colors.white,
      },
    },
    padding: 10,
    marginTop: -10,
  },
  tbselected: {
    '& .MuiToggleButton-label': {
      color: `${Colors.white} !important`,
    },
    '&.MuiToggleButton-root': {
      backgroundColor: (props: { primaryColor: string }) => `${props.primaryColor} !important`,
    },
  },
  tblabel: {
    color: Colors.black,
    fontSize: (props: ISegmentedControlGroupStyles) =>
      props?.mobile ? MobileFontSizes.xs : MobileFontSizes.sm,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
  tggrouped: {
    width: (props: ISegmentedControlGroupStyles) =>
      props.fullWidth && `calc(100%/${props.valueLength})`,
    '&:not(:last-child)': {
      borderTop: (props: { primaryColor: string }) => `2px solid ${props.primaryColor} !important`,
      borderBottom: (props: { primaryColor: string }) =>
        `2px solid ${props.primaryColor} !important`,
      borderLeft: `none`,
      borderRight: `none`,
    },
    '&:not(:first-child)': {
      borderTop: (props: { primaryColor: string }) => `2px solid ${props.primaryColor} !important`,
      borderBottom: (props: { primaryColor: string }) =>
        `2px solid ${props.primaryColor} !important`,
      borderRight: (props: { primaryColor: string }) =>
        `2px solid ${props.primaryColor} !important`,
      borderLeft: `none`,
    },
    '&:last-child': {
      borderTopRightRadius: '15px !important',
      borderBottomRightRadius: '15px !important',
      borderTop: (props: { primaryColor: string }) => `2px solid ${props.primaryColor} !important`,
      borderBottom: (props: { primaryColor: string }) =>
        `2px solid ${props.primaryColor} !important`,
      borderRight: (props: { primaryColor: string }) =>
        `2px solid ${props.primaryColor} !important`,
      borderLeft: `none`,
    },
    '&:first-child': {
      borderTopLeftRadius: '15px !important',
      borderBottomLeftRadius: '15px !important',
      border: (props: { primaryColor: string }) => `2px solid ${props.primaryColor} !important`,
    },
  },
  tggroupedRoot: {
    width: (props: ISegmentedControlGroupStyles) => props.fullWidth && '100%',
  },
});

export const BrancherSegmentedControlGroup = (props: ISegmentedControlGroup) => {
  const { value, updateValue, options, question } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const phablet = useMediaQuery(useTheme().breakpoints.between('sm', 'md'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({
    ...props,
    valueLength: options.length,
    mobile,
    phablet,
    primaryColor,
  });

  const handleChange = (e, newValue) => {
    if (!!newValue) {
      updateValue(newValue);
    }
  };

  return (
    <>
      {question && (
        <Grid item xs={12} container justify="center">
          <Text variant="md" fontWeight={600} marginBottom={30}>
            {question}
          </Text>
        </Grid>
      )}
      <ToggleButtonGroup
        classes={{ grouped: styles.tggrouped, root: styles.tggroupedRoot }}
        exclusive
        value={value}
        onChange={handleChange}
      >
        {options?.map((v, i) => (
          <ToggleButton
            classes={{
              root: styles.tbroot,
              label: styles.tblabel,
              selected: styles.tbselected,
            }}
            key={i}
            value={v.value}
          >
            {v.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
