import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NextButton, PrevButton } from '../InputFields/BrancherButton';
import { GetRoute } from '../Routing';
import { IPageLayout } from './PageLayout';
import { Text } from './Text';
import { BrancherDivider } from './BrancherDivider';
import { BrancherLinkText } from './BrancherLinkText';
import { IStoreTypes } from '../../store/storeTypes';

export const FeaturePageLayout: React.FC<IPageLayout> = ({
  pageTitle,
  titleDivider = true,
  showInsights = false,
  backButtonRoute,
  backButtonLabel = 'Back',
  nextButtonLabel = 'Next',
  nextButtonRoute,
  children,
}) => {
  const hasPersonalityValuesInsights = useSelector(
    (state: IStoreTypes) => state.user?.hasPersonalityValuesInsights,
  );
  return (
    <>
      {(backButtonRoute || nextButtonRoute) && (
        <Grid container item justify="space-between" alignItems="center" xs={11} lg={10}>
          {backButtonRoute && (
            <Grid item>
              <Text variant="lg" color="purple" fontWeight={600} marginBottom={20}>
                <Link to={GetRoute(backButtonRoute).path}>
                  <PrevButton variant="text" color="primary">
                    {backButtonLabel}
                  </PrevButton>
                </Link>
              </Text>
            </Grid>
          )}
          {nextButtonRoute && (
            <Grid item>
              <Text variant="lg" color="purple" fontWeight={600} marginBottom={20}>
                <Link to={GetRoute(nextButtonRoute).path}>
                  <NextButton variant="text" color="primary">
                    {nextButtonLabel}
                  </NextButton>
                </Link>
              </Text>
            </Grid>
          )}
        </Grid>
      )}

      {showInsights && hasPersonalityValuesInsights && (
        <Grid item xs={11} lg={10} container alignItems="center" justify="center">
          <Box mt={-3} mb={2} width="inherit">
            <Alert
              variant="outlined"
              severity="info"
              action={
                <IconButton
                  size="small"
                  onClick={() => window.location.assign(GetRoute('insights').path)}
                >
                  <ArrowForward />
                </IconButton>
              }
            >
              <BrancherLinkText
                variant="sm"
                fontWeight={700}
                color="purple"
                href={GetRoute('insights').path}
                underline="always"
              >
                Checkout your new Personality and Values Insights!
              </BrancherLinkText>
            </Alert>
          </Box>
        </Grid>
      )}

      <Grid item xs={11} lg={10}>
        <Text variant="lg" color="purple" fontWeight={700} marginBottom={!titleDivider && 40}>
          {pageTitle}
        </Text>
      </Grid>
      {titleDivider && (
        <Grid item xs={11} lg={10}>
          <BrancherDivider marginBottom={40} marginTop={6} />
        </Grid>
      )}
      <Grid container item xs={11} lg={10} justify="center">
        {children}
      </Grid>
    </>
  );
};
