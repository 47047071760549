import * as React from 'react';
import Chart, { Props } from 'react-apexcharts';
import { EValues, ValuesBrancherNames } from '../../Form/Values/ValuesFormConfig';
import { Colors } from '../../consts/colors';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { TAllPersonality } from '../../store/reducers/UserInfoReducer';

interface IBrancherRadarChart extends Props {
  categories: Array<TAllPersonality | EValues>;
  yourCategoryValues: number[];
  partnerCategoryValues?: number[];
}

// https://apexcharts.com/docs/chart-types/radar/
export const BrancherRadarChart: React.FC<IBrancherRadarChart> = (props) => {
  const { categories, partnerCategoryValues, yourCategoryValues } = props;
  const partnerName = useSelector((state: IStoreTypes) => state.user?.sessionPair?.name);

  const dataSeries = [
    {
      name: 'You',
      data: yourCategoryValues,
      color: Colors.purple,
    },
  ];

  if (!!partnerCategoryValues) {
    dataSeries.push({
      name: partnerName.split(' ')[0],
      data: partnerCategoryValues,
      color: Colors.emerald,
    });
  }

  const a = {
    options: {
      // https://apexcharts.com/docs/options/grid/
      grid: {
        padding: {
          top: -50,
          bottom: -10,
        },
      },
      chart: {
        // width: '100%',
        id: 'value-insights',
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: 'top',
        itemMargin: {
          vertical: 0,
        },
      },
      markers: {
        size: 3,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: Colors.chartLines,
            fill: {
              colors: [Colors.backgroundLightPurple],
            },
          },
        },
      },
      yaxis: {
        show: false,
        lines: {
          show: false,
        },
        labels: {
          show: false,
          formatter: () => '',
        },
      },
      xaxis: {
        labels: {
          show: true,
          formatter: (value) => value,
        },
        categories: categories.map((d) => ValuesBrancherNames[d]),
      },
    },
    series: dataSeries,
  } as any;

  return (
    <div id="value-insights" style={{ width: '100%', marginBottom: -60, marginLeft: '-22%' }}>
      <Chart options={a.options} series={a.series} width="150%" type="radar" />
    </div>
  );
};
