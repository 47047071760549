import * as React from 'react';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { IPairInfo } from '../../store/reducers/UserInfoReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { ProfilePicture } from './ProfilePicture';

const useStyles = makeStyles({
  differencesSimilarities: {
    height: 'fit-content',
    marginTop: (props: { mobile: boolean }) => props.mobile && 30,
  },
  excitedIntroduction: {
    marginBottom: 25,
  },
});

export interface IPairReasons {
  availablePairingProfile?: IProfileData;
  availablePairingReasons?: IPairInfo['pairingReasons'];
}

export const PairReasons: React.FC<IPairReasons> = ({
  availablePairingProfile,
  availablePairingReasons,
}) => {
  const styles = useStyles({ mobile: useTheme().breakpoints.down('sm') });
  const profileData =
    availablePairingProfile ?? useSelector((state: IStoreTypes) => state.profileForm?.pairProfile);
  const pairingReasons =
    availablePairingReasons ??
    useSelector((state: IStoreTypes) => state.user.sessionPair?.pairingReasons);
  const sesPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const profileType =
    sesPosition === ProgramPositions.mentor ? ProgramPositions.mentee : ProgramPositions.mentor;

  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);

  const createPairingReasonsContent = (): string => {
    let personalityValuesContent = [];
    if (pairingReasons?.personality) {
      personalityValuesContent.push('personality');
    }
    if (pairingReasons?.values) {
      personalityValuesContent.push('values');
    }
    return [...createCustomPairingContent(), ...personalityValuesContent].join(', ');
  };

  const createCustomPairingContent = (): string[] => {
    let content = [];
    if (pairingReasons?.custom) {
      pairingReasons.custom.forEach((reason) => {
        content.push(reason.readableName.toLowerCase());
      });
    }
    return content;
  };

  const createPersonalityPairingContent = (): string => {
    let content = [];
    if (pairingReasons?.personality) {
      pairingReasons.personality.forEach((reason) => {
        if (reason.attributeName === 'neuroticism') {
          content.push('confidence');
        } else {
          content.push(reason.attributeName.toLowerCase());
        }
      });
    }
    return readableJoin(content);
  };

  const createValuesPairingContent = (): string => {
    let content = [];
    if (pairingReasons?.values) {
      pairingReasons.values.forEach((reason) => {
        content.push(reason.attributeName.toLowerCase());
      });
    }
    return readableJoin(content);
  };

  const readableJoin = (contents: string[]): string => {
    const commaContents = contents.slice(0, contents.length - 1);
    return commaContents.join(', ').concat(` and ${contents[contents.length - 1]}`);
  };

  const excitedText = availablePairingReasons
    ? ' We are excited to introduce you to:'
    : ` We are excited to introduce you to your ${roleLabels[profileType]}:`;

  return (
    <Grid container>
      <Grid item className={styles.excitedIntroduction}>
        <span aria-label="excited" role="img">
          🎉
        </span>
        <Text variant="sm" fontWeight={600} display="inline">
          {excitedText}
        </Text>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Grid item>
              <ProfilePicture src={profileData?.[EProfileAttributes.IMAGE]} />
            </Grid>
            <Grid item xs={9} container direction="column">
              <Grid item>
                <Text variant="lg" color="purple" fontWeight={600}>
                  {profileData?.[EProfileAttributes.FIRST_NAME]}{' '}
                  {profileData?.[EProfileAttributes.LAST_NAME]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4} marginBottom={4}>
                  {profileData?.[EProfileAttributes.JOB_TITLE]}
                </Text>
              </Grid>
              <Grid item container alignItems="center">
                <Text variant="xs" fontWeight={600} color="purple" display="inline">
                  {capitalize(roleLabels[profileType])}
                </Text>
                <Box
                  display="inline"
                  bgcolor={primaryColor}
                  height={4}
                  width={4}
                  borderRadius="50%"
                  ml={1}
                  mr={1}
                />
                <Text variant="xs" color="tertiaryGrey" display="inline">
                  {profileData?.[EProfileAttributes.ORGANISATION]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4}>
                  {profileData?.[EProfileAttributes.LOCATION]}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} container direction="column" spacing={1}>
          <Grid item container alignItems="center">
            <Text variant="sm" color="secondaryGrey" fontWeight={500}>
              You were{' '}
              {availablePairingReasons
                ? 'introduced'
                : `paired with your ${roleLabels[profileType]}`}{' '}
              through Brancher's unique evidence-based pairing algorithm. We think you're great for
              each other because of your compatible {createPairingReasonsContent()} and more!
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BrancherDivider marginTop={40} marginBottom={30} />
      </Grid>
      <Grid item xs={12}>
        <Text variant="sm" fontWeight={700} marginBottom={40}>
          Curious to know the specifics?
        </Text>
      </Grid>
      <Grid item container spacing={2} xs={12} sm={9}>
        {pairingReasons?.custom && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img src={`${GetIllustrationLink('developRocket.png')}`} alt="develop-rocket" />
            </Grid>
            <Grid item xs={9}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                You have a compatible:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {readableJoin(createCustomPairingContent())}.
              </Text>
            </Grid>
          </Grid>
        )}
        {pairingReasons?.personality && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img src={`${GetIllustrationLink('explore.png')}`} alt="explore" />
            </Grid>
            <Grid item xs={9}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                You have compatible personality attributes:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {createPersonalityPairingContent()}.
              </Text>
            </Grid>
          </Grid>
        )}
        {pairingReasons?.values && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img src={`${GetIllustrationLink('pairingReasons.png')}`} alt="pairing-reasons" />
            </Grid>
            <Grid item xs={9}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                You have compatible views when considering the values:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {createValuesPairingContent()}.
              </Text>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} sm={3} className={styles.differencesSimilarities}>
        <Grid item>
          <Text variant="sm" fontWeight={600}>
            In addition to your similarities, you will also discover your differences. We encourage
            you to embrace and explore these differences.
          </Text>
        </Grid>
        <Grid item>
          <Text variant="sm" color="secondaryGrey" fontWeight={500} marginTop={25}>
            Remember, compatibility does not equal similarity - we value diversity and will match
            you with a {roleLabels[profileType]} who will compliment, challenge and stretch you.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
