import { Colors } from '../consts/colors';

export const MuiCircularProgress = (brandPrimary: string) => ({
  root: {
    color: brandPrimary,
  },
  colorPrimary: {
    color: Colors.white,
  },
  colorSecondary: {
    color: brandPrimary,
  },
});
