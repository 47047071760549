import { Colors } from '../consts/colors';

export const MuiSwitch = (brandPrimary: string) => ({
  switchBase: {
    color: Colors.lightPurple,
    '&$checked': {
      color: brandPrimary,
    },
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: brandPrimary,
      '& + $track': {
        backgroundColor: brandPrimary,
      },
    },
  },
});
