import * as React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { PropTypes } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { Colors, IColors } from '../../consts/colors';
import { getFontColor, getFontSize, getFontWeight } from '../../utils/TextUtils';
import { TextVariantValues } from '../../types/TextTypes';
import { WindowSizeVariants } from '../../types/WindowSizeTypes';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  texts: {
    marginTop: (props: IText) => props.marginTop,
    marginBottom: (props: IText) => props.marginBottom,
    marginLeft: (props: IText) => props.marginLeft,
    marginRight: (props: IText) => props.marginRight,
    borderBottom: (props: IText) => props.borderBottom && `2px solid ${Colors.lightGrey}`,
    textDecoration: (props: IText) => props.textDecoration && props.textDecoration,
    textDecorationColor: (props: IText) => props.color,
    verticalAlign: (props: IText) => props.verticalAlign,
    fontSize: (props: IText) => props.fontSize,
    fontWeight: (props: IText) => props.fontWeight,
    lineHeight: (props: IText) => (props.lineHeight ? props.lineHeight : 1.2),
    opacity: (props: IText) => props.opacity,
    color: (props: IText) => props.color,
    padding: (props: IText) => props.padding,
    display: (props: IText) => props.inlineBlock && 'inline-block',
    textTransform: 'none',
    // This is here for empty spaces around links - Except the nav links, it causes problems with Safari
    whiteSpace: (props: IText) => (!props.noPreWrap ? 'pre-wrap' : 'initial'),
    cursor: (props: IText) => props?.pointerOnHover && 'pointer',
    wordWrap: (props: IText) => (props?.wordWrap ? 'break-word' : 'initial'),
    wordBreak: (props: IText) => (props?.wordBreak ? 'break-word' : 'initial'),
  },
});

export interface IText extends Pick<TypographyProps, 'className' | 'id'> {
  variant: TextVariantValues;
  children: React.ReactNode;
  component?: 'h1' | 'h2' | 'h3' | 'p' | 'label';
  lineHeight?: number;
  fontSize?: number;
  fontWeight?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  color?: IColors;
  borderBottom?: boolean;
  pointerOnHover?: boolean;
  padding?: number | string;
  align?: PropTypes.Alignment;
  opacity?: number;
  display?: 'block' | 'inline';
  inlineBlock?: boolean;
  noPreWrap?: boolean;
  wordWrap?: boolean;
  wordBreak?: boolean;
  textDecoration?: string;
  tabIndex?: number;
  verticalAlign?: 'top' | 'center' | 'bottom';
  overrideColor?: string;
}

export const Text = (props: IText) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const size = mobile ? WindowSizeVariants.mobile : WindowSizeVariants.tabletDesktop;
  const fontSize = props.fontSize ? props.fontSize : getFontSize(size, props.variant);
  const fontWeight = props.fontWeight ? props.fontWeight : getFontWeight(size, props.variant);
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const color = getFontColor(props.color, primaryColor);
  const styles = useStyles({ ...props, fontSize, fontWeight, color });
  const { children, align, display, className, tabIndex, id } = props;
  return (
    <Typography
      tabIndex={tabIndex}
      id={id}
      className={cx(styles.texts, className)}
      align={align}
      display={display}
      component={
        props.component ?? props.variant === 'xl' ? 'h1' : props.variant === 'lg' ? 'h2' : 'p'
      }
    >
      {children}
    </Typography>
  );
};
