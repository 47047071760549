import { Colors } from '../consts/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const breakpoints = createMuiTheme().breakpoints;

export const MuiTab = (brandPrimary: string) => ({
  root: {
    color: Colors.black,
  },
  textColorPrimary: {
    [breakpoints.down('sm')]: {
      fontSize: 15,
      lineHeight: '18px',
      paddingBottom: 10,
    },
    [breakpoints.up('md')]: {
      fontSize: 25,
      lineHeight: '30px',
      paddingBottom: 15,
    },
    color: Colors.black,
    fontWeight: 600,
    textTransform: 'none',
    '&.Mui-selected': {
      color: brandPrimary,
    },
  },
});
