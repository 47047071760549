import { Colors } from '../consts/colors';

export const MuiSelect = (brandPrimary: string) => ({
  root: {
    minWidth: '100%',
  },
  select: {
    background: Colors.transparent,
    color: brandPrimary,
    borderRadius: 15,
    paddingLeft: 24,
    paddingTop: 14,
    paddingBottom: 15,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    '&:focus': {
      borderRadius: 15,
      backgroundColor: Colors.lightPurple,
      color: Colors.white,
    },
  },
  outlined: {
    borderRadius: 15,
    fontWeight: 600,
    background: brandPrimary,
    color: Colors.white,
    border: `1px solid ${brandPrimary}`,
    '&:hover': {
      outline: 'none',
    },
  },
  icon: {
    color: Colors.white,
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
});
