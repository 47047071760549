import * as React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Text } from './Text';
import { TextVariantValues } from '../../types/TextTypes';
import { Colors } from '../../consts/colors';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

type List = string | React.ReactElement;

interface IBrancherList {
  listItems: List[];
  color?: 'black' | 'purple';
  fontWeight?: number;
  marginBottom?: number;
  variant?: TextVariantValues;
  listStyleType?: 'circle' | 'lower-alpha';
  wordWrap?: boolean;
}

const useStyles = makeStyles({
  listItem: {
    color: (props: { primaryColor: string }) => props.primaryColor,
    marginLeft: -20,
    listStyleType: (props: { listStyleType?: IBrancherList['listStyleType'] }) =>
      props?.listStyleType,
  },
});

export const BrancherList = (props: IBrancherList) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const {
    listItems,
    wordWrap,
    fontWeight = 600,
    marginBottom = 10,
    variant = 'sm',
    color = 'black',
  } = props;
  return (
    <ul>
      {listItems.map((l, i) => (
        <li className={styles.listItem} key={i}>
          {typeof l === 'string' ? (
            <Text
              variant={variant}
              fontWeight={fontWeight}
              marginBottom={marginBottom}
              color={color}
              wordWrap={wordWrap}
            >
              {l}
            </Text>
          ) : (
            l
          )}
        </li>
      ))}
    </ul>
  );
};
