import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton, PrevButton } from '../Components/InputFields/BrancherButton';
import { BrancherSnackbar } from '../Components/General/BrancherSnackbar';
import { hasDefinedValue } from '../utils/validators/helpers';
import { GeneralForm } from './General/GeneralForm';
import { GeneralFormIndex, GeneralFormQuestions as GeneralQs } from './General/GeneralFormConfig';
import { IStoreTypes } from '../store/storeTypes';
import { ValuesForm } from './Values/ValuesForm';
import { ValuesInfo } from './Values/ValuesInfo';
import { PersonalityInfo } from './Personality/PersonalityInfo';
import { PersonalityForm } from './Personality/PersonalityForm';
import { IsValuesV2, ValuesFormIndex, ValuesFormQuestions } from './Values/ValuesFormConfig';
import {
  IsPersonalityV2,
  PersonalityFormIndex,
  PersonalityFormQuestions,
} from './Personality/PersonalityFormConfig';
import { FinalisingForm } from './Finalising/FinalisingForm';
import {
  FinalisingFormIndex,
  FinalisingFormQuestions as FinalisingQs,
} from './Finalising/FinalisingFormConfig';
import { BrancherDivider } from '../Components/General/BrancherDivider';
import { BrancherProgramStepper } from '../QualifyingForm/BrancherProgramStepper';
import { ProgramPositions } from '../QualifyingForm/ProgramPositionOptions';
import { UtilSaveFormData } from '../store/actions/FinalisingActions';
import { GetRoute } from '../Components/Routing';
import { MentoringSubmitted } from './MentoringSubmitted';
import { BrancherDispatch } from '../store/actions';
import { Colors } from '../consts/colors';
import { FormPaneHeight, FormStepper } from './FormStepper';
import { EComponentType } from '../store/reducers/UserInfoReducer';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: 140,
  },
  form: {
    height: '-webkit-fill-available',
    minHeight: '-webkit-fit-content',
    marginTop: 10,
    padding: (props: { mobile: boolean }) => (props.mobile ? `10px 10px 30px` : `10px 40px 30px`),
    borderRadius: 15,
    width: '100%',
    background: Colors.white,
    boxShadow: `0px 3px 6px ${Colors.darkGrey}`,
  },
  formContainer: {
    background: Colors.backgroundGrey,
  },
  fixedActionPane: {
    position: 'fixed',
    bottom: 0,
    height: FormPaneHeight,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
});

export const Form = () => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const generalFormValue = useSelector((state: IStoreTypes) => state.general);
  const valuesFormValue = useSelector((state: IStoreTypes) => state.values);
  const personalityFormValue = useSelector((state: IStoreTypes) => state.personality);
  const [savingForm, setSavingForm] = React.useState<boolean>(false);
  const styles = useStyles({ mobile });

  // To allow post response form editing
  const editApplicationForm: boolean = !!useSelector(
    (state: IStoreTypes) => state.user?.editApplicationForm,
  );
  const formStep = useSelector((state: IStoreTypes) => state.qualify?.step);
  const savedFormStep = editApplicationForm ? 1 : formStep;
  const finalisingFormValue = useSelector((state: IStoreTypes) => state.finalising);
  const submitted = editApplicationForm ? false : finalisingFormValue?.submitted;

  const isMentee =
    useSelector((state: IStoreTypes) => state.qualify.position) === ProgramPositions.mentee;
  const [finished, setFinished] = React.useState(false);
  const [open, controlSnackbar] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = React.useState(false);
  const [snackbarMessage, controlSnackbarMessage] = React.useState('');
  // eslint-disable-next-line no-use-before-define
  const [step, setStep] = React.useState(savedFormStep !== 0 ? savedFormStep : 1);
  const dispatch = useDispatch();

  // Scrolls the user to the top of the page after changing step
  React.useEffect(() => {
    window.scrollTo({ top: -20, behavior: 'smooth' });
  }, [step]);

  const actualGeneralQs = GeneralQs(isMentee);
  const actualFinalisingQs = FinalisingQs(isMentee);
  const GeneralFormQuestions = GeneralFormIndex(isMentee);
  const ValuesFormIndexes = ValuesFormIndex();
  const PersonalityFormIndexes = PersonalityFormIndex();
  const actualValuesQs = ValuesFormQuestions();
  const actualPersonalityQs = PersonalityFormQuestions();
  const isValuesV2 = IsValuesV2();
  const isPersonalityV2 = IsPersonalityV2();
  const FinalisingFormQuestions = FinalisingFormIndex();
  const GeneralLength = GeneralFormQuestions.length;
  const ValuesLength = isValuesV2 ? actualValuesQs.length : ValuesFormIndexes.length / 5;
  const PersonalityLength = isPersonalityV2
    ? actualPersonalityQs.length
    : PersonalityFormIndexes.length / 6;
  const GeneralValueLength = GeneralLength + ValuesLength;
  const GeneralValuePersonalityLength = GeneralValueLength + PersonalityLength;
  const FinalisingFormLength = FinalisingFormQuestions.length;
  const stepAmt = GeneralValuePersonalityLength + FinalisingFormLength;

  const hasPersonality = PersonalityLength > 0;
  const hasFinalising = FinalisingFormLength > 0;

  const saveFormData = (submitFinal: boolean) => {
    const overrideSubmitFinal = editApplicationForm ? true : submitFinal;
    setSavingForm(true);
    BrancherDispatch(
      dispatch,
      UtilSaveFormData(
        editApplicationForm ? stepAmt : step,
        overrideSubmitFinal,
        editApplicationForm,
        (response) => {
          if (!submitFinal || editApplicationForm) {
            setSavingForm(false);
            if (response.success) {
              controlSnackbarMessage('Successfully saved your data.');
              controlSnackbar(true);
              if (editApplicationForm) {
                setRedirectToDashboard(true);
              }
            } else {
              if (response.redirectToLogin) {
                setRedirect(true);
              } else {
                controlSnackbarMessage(
                  'Something went wrong when saving your data, please try again.',
                );
                controlSnackbar(true);
              }
            }
          }
        },
      ),
    );
  };

  // Checks whether the required fields have a value
  const validateValue = (): boolean => {
    let hasValue = false;
    if (step <= GeneralLength) {
      const currQuestion = actualGeneralQs[step - 1];
      const notMandatory = currQuestion?.notMandatory === true;
      const currentValue = generalFormValue[GeneralFormQuestions[step - 1]];
      const actualValue = generalFormValue?.[currQuestion.name];
      if (notMandatory) {
        hasValue = true;
      } else {
        hasValue = currentValue;
      }
      // For when they need to fill out a certain amount of fields
      if (currQuestion?.componentType === EComponentType.DND) {
        hasValue =
          !!actualValue &&
          actualValue
            ?.filter((c) => c.id !== currQuestion.optionsColumn)
            .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
      } else if (
        currQuestion?.componentType === EComponentType.TOGGLE &&
        !currQuestion?.exclusive
      ) {
        if (currQuestion?.minimumAmount) {
          hasValue = currentValue?.length >= currQuestion.minimumAmount;
        } else {
          hasValue = currentValue?.length === currQuestion.maximumAmount;
        }
      } // For array lengths
      else if (currentValue && typeof currentValue === 'object') {
        if (currentValue.length < 1) {
          hasValue = false;
        }
      }
    } // For pages without questions
    else if (step === GeneralLength + 0.5 || step === GeneralValueLength + 0.5) {
      hasValue = true;
    } else if (!isValuesV2) {
      if (step === GeneralLength + 1) {
        const valuesNames = ValuesFormIndexes.slice(0, 5);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 2) {
        const valuesNames = ValuesFormIndexes.slice(5, 10);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 3 && ValuesLength / 5 > 2) {
        const valuesNames = ValuesFormIndexes.slice(10, 15);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 4 && ValuesLength / 5 > 3) {
        const valuesNames = ValuesFormIndexes.slice(15, 20);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 1 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(0, 6);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 2 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(6, 12);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 3 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(12, 18);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 4 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(18, 24);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValuePersonalityLength && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(18, 24);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step >= GeneralValuePersonalityLength && hasPersonality && hasFinalising) {
        const currQuestion = actualFinalisingQs[FinalisingFormQuestions.length - 1];
        const notMandatory = currQuestion?.notMandatory === true;
        const currentValue =
          finalisingFormValue[FinalisingFormQuestions[FinalisingFormQuestions.length - 1]];
        const actualValue = finalisingFormValue?.[currQuestion?.name];
        if (notMandatory) {
          hasValue = true;
        } else {
          hasValue = currentValue;
        }
        // For when they need to fill out a certain amount of fields
        if (currQuestion?.componentType === EComponentType.DND) {
          hasValue =
            !!actualValue &&
            actualValue
              ?.filter((c) => c.id !== currQuestion.optionsColumn)
              .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
        } else if (
          currQuestion?.componentType === EComponentType.TOGGLE &&
          !currQuestion?.exclusive
        ) {
          if (currQuestion?.minimumAmount) {
            hasValue = currentValue?.length >= currQuestion.minimumAmount;
          } else {
            hasValue = currentValue?.length === currQuestion.maximumAmount;
          }
        } // For array lengths
        else if (currentValue && typeof currentValue === 'object') {
          if (currentValue.length < 1) {
            hasValue = false;
          }
        }
      }
    } else if (isValuesV2 && (isPersonalityV2 || !hasPersonality)) {
      // just here for readability
      if (step <= GeneralValueLength) {
        const valuesStep = step - GeneralLength;
        const currentValue = valuesFormValue?.response?.[valuesStep - 1];
        hasValue = currentValue?.most?.length > 0 && currentValue?.least?.length > 0;
        // make this (step <= GeneralValueLength) if we want automatic question submission
        if (hasValue && !(step <= GeneralValueLength)) {
          setStep(step + 1);
          // controlNextSteps();
        }
      } else if (
        hasPersonality &&
        step > GeneralValueLength &&
        step <= GeneralValuePersonalityLength
      ) {
        const personalityStep = step - GeneralValueLength;
        const currQuestion = actualPersonalityQs[personalityStep - 1];
        const currentValue = personalityFormValue?.[currQuestion.name];
        hasValue = hasDefinedValue(currentValue);
        // if (hasValue && !(step <= GeneralValuePersonalityLength)) {
        //   setStep(step + 1);
        // }
      } else if (step > GeneralValuePersonalityLength && step <= stepAmt && hasFinalising) {
        const sectionStep = stepAmt - GeneralValuePersonalityLength - 1;
        const currQuestion = actualFinalisingQs[sectionStep];
        const notMandatory = currQuestion?.notMandatory === true;
        const currentValue = finalisingFormValue[sectionStep];
        const actualValue = finalisingFormValue?.[currQuestion.name];
        if (notMandatory) {
          hasValue = true;
        } else {
          hasValue = currentValue;
        }
        // For when they need to fill out a certain amount of fields
        if (currQuestion?.componentType === EComponentType.DND) {
          hasValue =
            !!actualValue &&
            actualValue
              ?.filter((c) => c.id !== currQuestion.optionsColumn)
              .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
        } else if (
          currQuestion?.componentType === EComponentType.TOGGLE &&
          !currQuestion?.exclusive
        ) {
          if (currQuestion?.minimumAmount) {
            hasValue = currentValue?.length >= currQuestion.minimumAmount;
          } else {
            hasValue = currentValue?.length === currQuestion.maximumAmount;
          }
        } // For array lengths
        else if (currentValue && typeof currentValue === 'object') {
          if (currentValue.length < 1) {
            hasValue = false;
          }
        }
      }
    }
    return hasValue;
  };

  const controlPrevSteps = () => {
    if (finished) {
      setFinished(false);
      setStep(step - 1);
    } // This is to put in the info screens
    else if (
      step === GeneralLength + 1 ||
      step === GeneralLength + 0.5 ||
      step === GeneralValueLength + 0.5 ||
      step === GeneralValueLength + 1
    ) {
      setStep(step - 0.5);
    } else {
      setStep(step - 1);
    }
  };

  const finalStep = step === stepAmt;
  const editApplicationFinalStep = editApplicationForm && finalStep;

  const controlNextSteps = () => {
    if (finished || editApplicationFinalStep) {
      saveFormData(true);
    } else if (finalStep) {
      // What does this do nowadays?
      setFinished(true);
    } // This is just to put in the info screens
    else if (
      step === GeneralLength ||
      step === GeneralLength + 0.5 ||
      step === GeneralValueLength ||
      step === GeneralValueLength + 0.5
    ) {
      setStep(step + 0.5);
    } else {
      setStep(step + 1);
    }
  };

  return (
    <Grid container alignItems="center" justify="center" className={styles.parentContainer}>
      {redirectToDashboard && <Redirect to={GetRoute('dashboard').path} />}
      {redirect && <Redirect to={GetRoute('login').path} />}
      <BrancherSnackbar open={open} controlClose={controlSnackbar} message={snackbarMessage} />
      {!submitted && (
        <FormStepper
          step={step - 1}
          generalQuestions={actualGeneralQs}
          valuesQuestions={actualValuesQs}
          personalityQuestions={actualPersonalityQs}
          finalisingQuestions={actualFinalisingQs}
          saveFormData={saveFormData}
          submittedFinalisingForm={finalisingFormValue.submitted}
          editApplicationForm={editApplicationForm}
        />
      )}

      <Grid
        container
        item
        xs={12}
        className={styles.formContainer}
        alignItems="center"
        justify="center"
      >
        <Grid container item xs={11} md={9} className={styles.form} justify="center">
          {step <= GeneralLength ? (
            <GeneralForm stepNumber={step} generalQuestions={actualGeneralQs} />
          ) : step === GeneralLength + 0.5 ? (
            <ValuesInfo />
          ) : step <= GeneralValueLength ? (
            <ValuesForm
              stepNumber={step}
              prevStepNumber={GeneralLength}
              valuesQuestions={actualValuesQs}
            />
          ) : step === GeneralValueLength + 0.5 && hasPersonality ? (
            <PersonalityInfo />
          ) : step <= GeneralValuePersonalityLength && hasPersonality && !submitted ? (
            <PersonalityForm
              stepNumber={step}
              prevStepNumber={GeneralValueLength}
              personalityQuestions={actualPersonalityQs}
            />
          ) : hasFinalising && step <= stepAmt && !finished && !submitted ? (
            <FinalisingForm
              stepNumber={step}
              isMentee={isMentee}
              prevStepNumber={GeneralValuePersonalityLength}
            />
          ) : !submitted && finished ? (
            <>
              <Grid item xs={12} md={9}>
                <MentoringSubmitted />
              </Grid>
              <Grid item xs={12} md={9}>
                <BrancherDivider marginTop={9} marginBottom={20} />
              </Grid>
            </>
          ) : (
            !editApplicationForm && (
              <BrancherProgramStepper step={step} setStep={setStep} isMentee={isMentee} />
            )
          )}
        </Grid>
      </Grid>
      {(!submitted || editApplicationForm) && (
        <Grid container item xs={12} justify="center" className={styles.fixedActionPane}>
          <Grid container xs={11} md={9} justify="space-between" alignItems="center" item>
            <Grid item xs={5} md={3}>
              <PrevButton
                onClick={controlPrevSteps}
                fontSize={20}
                disabled={!(step > 1 && !submitted)}
                fullWidth
                color="secondary"
              >
                Previous
              </PrevButton>
            </Grid>
            <Grid item xs={5} md={3}>
              <NextButton
                fullWidth
                fontSize={20}
                loading={savingForm}
                disabled={!validateValue() || savingForm}
                onClick={controlNextSteps}
              >
                {finished || editApplicationFinalStep ? 'Submit' : 'Next'}
              </NextButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
