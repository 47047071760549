import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Send from '@material-ui/icons/Send';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherDispatch, UtilCreateSupportRequest } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';

export enum ESupportOptions {
  PLATFORM_ISSUE = 'PLATFORM_ISSUE',
  RELATIONSHIP_ISSUE = 'RELATIONSHIP_ISSUE',
  PARTNER_NOT_RESPONDING = 'PARTNER_NOT_RESPONDING',
  CONFIDENTIAL = 'CONFIDENTIAL',
  LEAVE_PROGRAM = 'LEAVE_PROGRAM',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  OTHER = 'OTHER',
}

interface ISupportRequestForm {
  handleClose: () => void;
}

export const SupportRequestForm: React.FC<ISupportRequestForm> = ({ handleClose }) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const [supportOption, setSupportOption] = React.useState<ESupportOptions>(ESupportOptions.OTHER);
  const [requestText, setRequestText] = React.useState<string>('');
  const [sendingSupportRequest, setSendingSupportRequest] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const partnerPosition = isMentee
    ? roleLabels[ProgramPositions.mentor]
    : roleLabels[ProgramPositions.mentee];

  const supportOptions: Array<{ label: string; value: ESupportOptions }> = [
    {
      label: 'Issue or trouble with platform',
      value: ESupportOptions.PLATFORM_ISSUE,
    },
    {
      label: `Conflict or relationship issue with my ${partnerPosition}`,
      value: ESupportOptions.RELATIONSHIP_ISSUE,
    },
    {
      label: `My ${partnerPosition} won't respond to me`,
      value: ESupportOptions.PARTNER_NOT_RESPONDING,
    },
    {
      label: 'Confidential enquiry',
      value: ESupportOptions.CONFIDENTIAL,
    },
    {
      label: 'Leave the program',
      value: ESupportOptions.LEAVE_PROGRAM,
    },
    {
      label: 'Feature request',
      value: ESupportOptions.FEATURE_REQUEST,
    },
    {
      label: 'Other',
      value: ESupportOptions.OTHER,
    },
  ];

  const sendSupportRequest = () => {
    setSendingSupportRequest(true);
    BrancherDispatch(
      dispatch,
      UtilCreateSupportRequest(supportOption, requestText, () => {
        setSendingSupportRequest(false);
        handleClose();
      }),
    );
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <BrancherSelect
          fullWidth
          options={supportOptions}
          name="support-request-options"
          value={supportOption}
          updateValue={(o: ESupportOptions) => setSupportOption(o)}
          inputLabel="Support Options"
        />
      </Grid>
      {!!supportOption && (
        <>
          <Grid item>
            <BrancherTextField
              fullWidth
              value={requestText}
              rows={6}
              multiline
              updateValue={setRequestText}
              label="What is your request?"
              placeholder="Your request..."
            />
          </Grid>
          {!!requestText && (
            <Grid item container justify="flex-end">
              <Box mt={4} display="flex">
                <ActionButton
                  endIcon={<Send color="action" />}
                  disabled={sendingSupportRequest}
                  onClick={sendSupportRequest}
                >
                  Send support request
                </ActionButton>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
