import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../consts/colors';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../store/storeTypes';

const useStyles = makeStyles({
  fill: {
    fill: (props: { selected: boolean; primaryColor: string }) =>
      !props.selected ? Colors.white : props.primaryColor,
  },
});

interface IHomeIcon {
  selected: boolean;
}

export const HomeIcon: React.FC<IHomeIcon> = ({ selected }) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ selected, primaryColor });
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <g id="expanded">
        <path
          className={styles.fill}
          d="M40,15.89a2.23,2.23,0,0,0-.88-1.5L20.15.15a.78.78,0,0,0-.92,0L.91,13.79A2.22,2.22,0,0,0,.49,17,2.28,2.28,0,0,0,2,17.84a2.35,2.35,0,0,0,1.72-.45l2-1.47a.75.75,0,0,0,0,.26V29.27a.75.75,0,0,0,.76.73h8.39a.74.74,0,0,0,.75-.73V22.65a4.43,4.43,0,0,1,8.85,0v6.62a.75.75,0,0,0,.76.73h8.34a.74.74,0,0,0,.75-.73V16.55a.17.17,0,0,0,0-.07l2,1.48a2.38,2.38,0,0,0,3.26-.4A2.2,2.2,0,0,0,40,15.89ZM32.76,28.53H25.94V22.65a5.94,5.94,0,0,0-11.88,0v5.88H7.18V17.8l12.6-9.38,13,9.75Z"
        />
      </g>
    </svg>
  );
};
