import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { GetRoute } from '../../Components/Routing';
import { loginRedirectUri } from '../../consts/cognito';
import {
  BrancherDispatch,
  SaveUserInfo,
  UtilLoginUser,
  UtilSSOSAMLCompatible,
  UtilUpdateRoleProfileActivities,
} from '../../store/actions';
import {
  EMFAChallengeNames,
  IUtilLoginUserResponse,
  UtilGetCompanyPrograms,
  UtilGetSessionInfo,
} from '../../store/actions/UserInfoActions';
import { IStoreTypes } from '../../store/storeTypes';
import { ESessionAttributes, getSessionAttribute, setSessionAttribute } from '../../utils/cookies';
import { decodeUserToken } from '../../utils/decodeUserToken';
import { GetLinkBase, GetSharedResourceLink } from '../../utils/LinkUtils';
import { BackSplashImagery } from './BackSplashImagery';
import { MFAVerify } from './MFAVerify';
import { SSOSignIn, SSOTypes } from './SSOSignIn';
import { SAMLSignIn } from './SAMLSignIn';
import { EProgressActivities } from '../../store/reducers/ProfileFormReducer';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { UpdatableCompanyInfo } from '../../store/reducers/UserInfoReducer';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  logo: {
    maxHeight: (props: { mobile: boolean }) => (props.mobile ? 100 : 180),
    width: (props: { mobile: boolean }) => (props.mobile ? 100 : 180),
    height: 'auto',
  },
});

export const Login = () => {
  const customCompanyLogo = useSelector(
    (state: IStoreTypes) => state.user?.[UpdatableCompanyInfo.CUSTOM_LOGO],
  );
  const expiredToken = useSelector((state: IStoreTypes) => state.user?.expiredToken);
  const googleAuth = useSelector((state: IStoreTypes) => state.user?.googleSignOn);
  const hasNumerousRoles = useSelector((state: IStoreTypes) => state.user?.hasNumerousRoles);
  const needsAdhocPairing = useSelector((state: IStoreTypes) => state.user?.requiresAdhocPairing);
  const userNeedsReviewing = useSelector((state: IStoreTypes) => state.user?.needsReviewing);
  const matched = useSelector((state: IStoreTypes) => state.user?.isMatched);

  // Keep it as a cookie
  const prevUserEmail = getSessionAttribute(ESessionAttributes.USER_EMAIL);
  const [email, setEmail] = React.useState<string>(prevUserEmail);
  const [password, setPassword] = React.useState<string>('');
  const [signedUp, hasSignedUp] = React.useState<boolean>(false);
  const [waiting, updateWaiting] = React.useState<boolean>(false);
  const [hasCheckedPrograms, setHasCheckedPrograms] = React.useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = React.useState<string[]>([]);
  const [apiError, setApiError] = React.useState<string>('');
  const [formProgramId, setFormProgramId] = React.useState<string>('');
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const [programs, setPrograms] = React.useState<Array<{ programId: string; programName: string }>>(
    [],
  );
  const [redirectToVerifyConfirmation, setRedirectToVerifyConfirmation] = React.useState<boolean>(
    false,
  );
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });

  // sso
  const [isSSO, setIsSSO] = React.useState<boolean>(false);
  const [isSAMLSSO, setIsSAMLSSO] = React.useState<boolean>(false);
  const [gettingSAMLCompatibility, setGettingSAMLCompatibility] = React.useState<boolean>(false);
  const [mfaSetupSuccessful, setMFASetupSuccessful] = React.useState<boolean>(false);
  const [ssoUserState, setSSOUserState] = React.useState<IUtilLoginUserResponse['data']>();

  // mfa states
  const [secretCode, setSecretCode] = React.useState<string>('');
  const [mfaAccessToken, setMFAAccessToken] = React.useState<string>('');
  const [session, setSession] = React.useState<string>('');
  const [challengeName, setChallengeName] = React.useState<EMFAChallengeNames>(undefined);

  const controlSetEmail = (currEmail: string) => {
    setEmail(currEmail);
    if (
      !!String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      setTimeout(controlSAMLCompatibility, 1000);
    }
  };

  React.useEffect(() => {
    if (!!prevUserEmail && !!password) {
      getCompanyPrograms(prevUserEmail, password, false, () => {
        const userPrevProgram = getSessionAttribute(ESessionAttributes.PREVIOUS_PROGRAM_ID);
        if (userPrevProgram) {
          setFormProgramId(userPrevProgram);
        }
      });
    } else if (!!prevUserEmail) {
      getSAMLCompatibility();
    }
  }, []);

  const handleProgram = (programValue: string) => {
    setFormProgramId(programValue);
  };

  const getSAMLCompatibility = () => {
    setGettingSAMLCompatibility(true);
    dispatch(
      UtilSSOSAMLCompatible(email, (isCompatible) => {
        setGettingSAMLCompatibility(false);
        setApiError('');
        dispatch(
          SaveUserInfo({
            googleSignOn: false,
            samlSignOn: true,
            customLogo: isCompatible.data?.customLogo,
            ssoProvider: isCompatible.data?.[UpdatableCompanyInfo.SSO_PROVIDER],
          }),
        );
        setPrograms([]);
        if (!!isCompatible.data?.compatible) {
          setIsSSO(true);
          setIsSAMLSSO(true);
        }
      }),
    );
  };

  const emailOnBlur = (e) => {
    if (email !== '' && e.target.value === email) {
      controlSAMLCompatibility();
    } else if (email === '') {
      setFieldErrors([...fieldErrors, 'email']);
    }
  };

  const controlSAMLCompatibility = () => {
    getSAMLCompatibility();
    if (fieldErrors?.length > 0) {
      setFieldErrors([]);
    } else {
      if (password !== '') {
        getCompanyPrograms(email, password, false);
      }
    }
  };

  const passwordOnBlur = (e) => {
    if (password !== '' && e.target.value === password && email !== '') {
      if (fieldErrors?.length > 0) {
        setFieldErrors([]);
      }
      getCompanyPrograms(email, password, false);
    } else if (password === '') {
      setFieldErrors([...fieldErrors, 'password']);
    }
  };

  const reConfirmUser = () => {
    dispatch(SaveUserInfo({ username: email }));
    setRedirectToVerifyConfirmation(true);
    if (!isSSO && !isSAMLSSO) {
      setApiError('There was an error authenticating you');
    }
  };

  const getCompanyPrograms = (
    userEmail: string,
    pwd: string,
    sso: boolean,
    cb?: (manyPrograms?: boolean) => void,
  ) => {
    setApiError('');
    dispatch(
      UtilGetCompanyPrograms(userEmail, pwd, sso, (programDetails) => {
        if (!hasCheckedPrograms) {
          setHasCheckedPrograms(true);
        }
        if (programDetails.success) {
          setInvalidEmail(false);
          setPrograms(programDetails?.data?.programs);
          setFormProgramId(programDetails?.data?.programs?.[0]?.programId);
          cb?.(programDetails?.data?.programs?.length > 1);
        } else {
          if (programDetails.message === 'RECONFIRM') {
            reConfirmUser();
          } else {
            setApiError(programDetails.message);
          }
          setInvalidEmail(true);
          setPrograms([]);
        }
      }),
    );
  };

  const controlSSOUser = (res: IUtilLoginUserResponse) => {
    window.history.replaceState({}, document.title, loginRedirectUri);
    const { message, data, success } = res;
    if (!success) {
      setApiError(message);
    } else {
      setEmail(data.email);
      setIsSSO(true);
      getCompanyPrograms(data.username, '', true, (hasNumerousPrograms) => {
        if (hasNumerousPrograms) {
          dispatch(SaveUserInfo({ IDToken: data.idToken, tokenExp: data.tokenExp }));
          setSSOUserState(data);
        } else {
          CreateSuccessfulLoginData(data, true);
        }
      });
    }
  };

  const checkProgramInfo = () => {
    BrancherDispatch(
      dispatch,
      UtilGetSessionInfo(ssoUserState.username, formProgramId, (sessionInfo) => {
        CreateSuccessfulLoginData({ ...ssoUserState, ...sessionInfo.data }, true);
      }),
    );
  };

  const CreateSuccessfulLoginData = (resData: IUtilLoginUserResponse['data'], SSO: boolean) => {
    const {
      idToken,
      companyId,
      companyName,
      programType,
      firstName,
      lastName,
      formId,
      applicationAlwaysOpen,
      positions,
      brandPrimaryColor,
      mentees,
      mentors,
      sessionPosition,
      isMatched,
      hasNumerousRoles: manyRoles,
      subscription,
      modules,
      menteeRoleId,
      mentorRoleId,
      programId,
      programName,
      email: adjustedEmail,
      supportEmail,
      sessionRoleId,
      sessionPair,
      username,
      tokenExp,
      userSub,
      customLogo,
      requiresAdhocPairing,
      hasAdhocPairing,
      mentoringPartnerRequests,
      actions,
      maximumMentorAmount,
      maximumMenteeAmount,
      roleLabels,
      groups,
      requiresApplicationApproval,
      needsReviewing,
      hasGoogleCalendar,
      hasAzureCalendar,
      userId,
      menteeGetStartedProgress,
      mentorGetStartedProgress,
      hasPersonalityValuesInsights,
      paidInsights,
    } = resData;
    const tokenData = !SSO
      ? decodeUserToken(idToken)
      : { username, email: adjustedEmail, id: userSub ?? userId, IDToken: idToken, tokenExp };
    const successfulLoginData = {
      ...tokenData,
      loggedIn: true,
      expiredToken: false,
      redirected: false,
      firstName,
      lastName,
      formId,
      companyId,
      companyName,
      programType,
      brandPrimaryColor: brandPrimaryColor ?? Colors.purple,
      hasPersonalityValuesInsights,
      applicationAlwaysOpen,
      maximumMentorAmount,
      maximumMenteeAmount,
      paidInsights,
      positions,
      mentees,
      mentors,
      subscription,
      modules,
      hasNumerousRoles: manyRoles,
      menteeRoleId,
      mentorRoleId,
      programId,
      programName,
      supportEmail,
      sessionRoleId,
      sessionPair,
      sessionPosition,
      customLogo,
      isMatched,
      groups,
      isSSO: SSO,
      requiresAdhocPairing,
      hasAdhocPairing,
      mentoringPartnerRequests,
      actions,
      roleLabels,
      requiresApplicationApproval,
      needsReviewing,
      hasGoogleCalendar,
      hasAzureCalendar,
      menteeGetStartedProgress,
      mentorGetStartedProgress,
    };
    setSessionAttribute(ESessionAttributes.USER_EMAIL, email);
    setSessionAttribute(ESessionAttributes.PREVIOUS_PROGRAM_ID, programId);
    dispatch(SaveUserInfo(successfulLoginData));
    if (positions.includes(ProgramPositions.mentee)) {
      BrancherDispatch(
        dispatch,
        UtilUpdateRoleProfileActivities(EProgressActivities.HAS_LOGGED_IN, {}, menteeRoleId),
      );
    }
    if (positions.includes(ProgramPositions.mentor)) {
      BrancherDispatch(
        dispatch,
        UtilUpdateRoleProfileActivities(EProgressActivities.HAS_LOGGED_IN, {}, mentorRoleId),
      );
    }
    hasSignedUp(true);
  };

  const postMFAResume = (relogin?: boolean, keepWaiting?: boolean) => {
    setChallengeName(undefined);
    setMFAAccessToken('');
    setSession('');
    setSecretCode('');
    setMFASetupSuccessful(relogin);
    updateWaiting(keepWaiting);
    if (relogin) {
      login();
    }
  };

  const login = () => {
    const errors = validateFields();
    if (errors.length === 0) {
      updateWaiting(true);
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(
        UtilLoginUser(email, password, formProgramId, (response) => {
          if (response.success) {
            if (response.data?.isMFA) {
              setSecretCode(response.data?.keyCode);
              setMFAAccessToken(response.data?.accessToken);
              setSession(response.data?.session);
              setChallengeName(response.data?.challengeName);
            } else {
              CreateSuccessfulLoginData(response.data, false);
            }
          } else {
            if (response?.message === 'RECONFIRM') {
              dispatch(SaveUserInfo({ username: email }));
              reConfirmUser();
              updateWaiting(false);
            } else {
              updateWaiting(false);
              setApiError(response?.message);
            }
          }
        }),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  const validateFields = (): string[] => {
    const errors: string[] = [];
    if (email === '') {
      errors.push('email');
    }
    if (password === '') {
      errors.push('password');
    }
    return errors;
  };

  const isLoginDisabled = (): boolean => {
    return (
      !formProgramId ||
      (waiting && !signedUp) ||
      invalidEmail ||
      !!gettingSAMLCompatibility ||
      !checkSAMLData()
    );
  };

  const checkSAMLData = (): boolean => {
    if (isSAMLSSO) {
      return !!ssoUserState?.username && !!formProgramId;
    } else {
      return true;
    }
  };

  const currProgramName = programs?.find((p) => formProgramId === p.programId)?.programName;

  return (
    <BackSplashImagery>
      <BrancherSnackbar
        controlClose={setMFASetupSuccessful}
        open={mfaSetupSuccessful}
        message="You have successfully setup your MFA! Please login to proceed."
      />
      {!!challengeName && (
        <MFAVerify
          challengeName={challengeName}
          secretCode={secretCode}
          session={session}
          programId={formProgramId}
          accessToken={mfaAccessToken}
          setResume={postMFAResume}
          username={email}
          setSessionInformation={(s) => CreateSuccessfulLoginData(s, false)}
        />
      )}
      {!userNeedsReviewing && signedUp && hasNumerousRoles && (matched || needsAdhocPairing) ? (
        <Redirect to={GetRoute('chooseRole').path} />
      ) : !userNeedsReviewing && signedUp && (needsAdhocPairing || matched) ? (
        <Redirect to={GetRoute('dashboard').path} />
      ) : (
        signedUp &&
        (!matched || userNeedsReviewing) && <Redirect to={GetRoute('qualifying').path} />
      )}
      <div tabIndex={0} id="login" />
      <Grid container direction="row" alignItems="center" justify="center">
        {redirectToVerifyConfirmation && <Redirect to={GetRoute('confirmRegistration').path} />}
        <Grid item xs={12}>
          <Grid container direction="column" justify="center">
            <Grid item container justify="center">
              <img
                src={
                  !!customCompanyLogo
                    ? `${GetLinkBase()}${customCompanyLogo}`
                    : GetSharedResourceLink('brancher.png')
                }
                alt="login-logo"
                className={styles.logo}
              />
            </Grid>
            <BrancherDottedDivider marginTop={mobile ? 15 : 20} marginBottom={mobile ? 15 : 30} />
            {apiError && (
              <Text
                variant="xs"
                color="red"
                id="api-error"
                marginTop={15}
                marginBottom={15}
                fontWeight={500}
              >
                {apiError}
              </Text>
            )}
            {expiredToken && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
                Your session has expired, please log back in to restart a secure session.
              </Text>
            )}
            <Grid container item direction="row" xs={12} justify="space-between">
              <Grid item xs={12}>
                <BrancherTextField
                  fullWidth
                  name="email"
                  value={email}
                  type="email"
                  autoCapitalize="off"
                  onBlur={emailOnBlur}
                  updateValue={controlSetEmail}
                  placeholder="Your Email Address"
                  label="Email Address"
                  disabled={isSSO}
                  error={fieldErrors.includes('email')}
                  helpText={fieldErrors.includes('email') && 'Please enter a valid email!'}
                />
              </Grid>
              {!isSSO && !isSAMLSSO && (
                <Grid item xs={12}>
                  <BrancherTextField
                    fullWidth
                    name="password"
                    type="password"
                    value={password}
                    onBlur={passwordOnBlur}
                    updateValue={setPassword}
                    placeholder="Enter Password"
                    label="Password"
                    error={fieldErrors.includes('password')}
                    helpText={
                      fieldErrors.includes('password') && 'Please enter a stronger password!'
                    }
                  />
                </Grid>
              )}
              {programs?.length > 1 ? (
                <Grid item xs={12}>
                  <BrancherSelect
                    fullWidth
                    options={programs
                      .map((p) => ({
                        label: p.programName,
                        value: p.programId,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label, 'en'))}
                    name="programs"
                    value={formProgramId}
                    updateValue={handleProgram}
                    inputLabel="Programs"
                  />
                </Grid>
              ) : programs?.length === 0 && hasCheckedPrograms && !isSSO && !isSAMLSSO ? (
                <Grid item xs={12} container justify="center">
                  <Text variant="xs" color="purple" fontWeight={600} marginTop={10} align="center">
                    We weren't able to match your email to any active program, you may need to
                    sign-up
                  </Text>
                </Grid>
              ) : (
                currProgramName && (
                  <Grid item xs={12} container justify="center">
                    <Text
                      variant="xs"
                      color="purple"
                      fontWeight={600}
                      marginTop={10}
                      align="center"
                    >
                      You are logging into "{currProgramName}"
                    </Text>
                  </Grid>
                )
              )}
            </Grid>
            {checkSAMLData() && (
              <Grid item>
                <Box marginTop={mobile && programs?.length > 1 ? 1 : mobile ? 3 : 4} />
                <NextButton
                  id="login-button"
                  fullWidth
                  onClick={!isSSO ? login : checkProgramInfo}
                  disabled={isLoginDisabled()}
                >
                  {waiting && !signedUp ? (
                    <CircularProgress color="primary" size={30} />
                  ) : isSSO ? (
                    'Proceed'
                  ) : (
                    'Login'
                  )}
                </NextButton>
              </Grid>
            )}
            {googleAuth && (
              <Grid item container justify="center">
                <Box marginTop={mobile ? 2 : 4} marginBottom={mobile ? 2 : 3}>
                  <SSOSignIn controlSSOUser={controlSSOUser} type={SSOTypes.GOOGLE} />
                </Box>
              </Grid>
            )}
            {isSAMLSSO || getSessionAttribute(ESessionAttributes.HAS_SAML) === 'true' ? (
              <Grid item container justify="center">
                <Box marginBottom={mobile ? 2 : 3} marginTop={mobile ? 2 : 3}>
                  <SAMLSignIn
                    setApiError={setApiError}
                    programId={
                      formProgramId ?? getSessionAttribute(ESessionAttributes.PREVIOUS_PROGRAM_ID)
                    }
                    email={email}
                    controlSSOUser={controlSSOUser}
                  />
                </Box>
              </Grid>
            ) : (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <BrancherRouterLink to={GetRoute('forgotPassword').path}>
                  <Text variant="sm" color="purple">
                    Forgot Password?
                  </Text>
                </BrancherRouterLink>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
