import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IReducerType } from './ReducerType';
import { ETrainingModules } from '../reducerTypes/TrainingReducer.types';
import { ECalendars } from '../../PostMatching/Meetings/Scheduler';
import { EPlatformTypes } from '../../consts/PlatformTypes';

export const PROFILE_CLEAR_FORM_DATA = 'PROFILE_CLEAR_FORM_DATA';
export const PROFILE_FORM_DATA = 'PROFILE_FORM_DATA';
export const PROFILE_PAIR_FORM_DATA = 'PROFILE_PAIR_FORM_DATA';

export const ProfileFormReducer = (state = formReducerInitialState, action: IReducerType) => {
  switch (action.type) {
    case PROFILE_CLEAR_FORM_DATA:
      return formReducerInitialState;
    case PROFILE_FORM_DATA:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
          [EProfileAttributes.IMAGE]: action.payload?.[EProfileAttributes.IMAGE],
        },
      };
    case PROFILE_PAIR_FORM_DATA:
      return { ...state, pairProfile: action.payload };
    default:
      return state;
  }
};

export enum EProfileAttributes {
  IMAGE = 'image',
  PHONE_NUMBER = 'phoneNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  ORGANISATION = 'organisation',
  LOCATION = 'location',
  CONTACT_EMAIL = 'contactEmail',
  LINKEDIN = 'linkedIn',
  CAREER_STORY = 'careerStory',
  PREFERRED_MEETING_TIMES = 'preferredMeetingTimes',
  PUBLIC_PROFILE = 'publicProfile',
  PUBLIC_PROFILE_RAW = 'publicProfileRaw',
  ALLOW_PARTNER_VIEW_INSIGHTS = 'allowPartnerViewInsights',
  ALLOW_PARTNER_VIEW_INSIGHTS_RAW = 'allowPartnerViewInsightsRaw',
  MAXIMUM_PARTNER_AMOUNT = 'maximumPartnerAmount',
}

export enum EProgressActivities {
  HAS_COMPLETED_PROFILE = 'hasCompletedProfile',
  HAS_UPLOADED_PROFILE_PIC = 'hasUploadedProfilePic',
  HAS_UPDATED_PROFILE = 'hasUpdatedProfile',
  HAS_VIEWED_PERSONAL_INSIGHTS = 'hasViewedPersonalInsights',
  HAS_VIEWED_MATCH_INSIGHTS = 'hasViewedMatchInsights',
  HAS_CREATED_GOAL = 'hasCreatedGoal',
  HAS_UPDATED_GOAL = 'hasUpdatedGoal',
  HAS_COMPLETED_GOAL = 'hasCompletedGoal',
  HAS_PARTICIPATED_IN_MEETING = 'hasParticipatedInMeeting',
  HAS_CREATED_MEETING = 'hasCreatedMeeting',
  HAS_COMPLETED_TRAINING = 'hasCompletedTraining',
  HAS_CREATED_PRIVATE_NOTE = 'hasCreatedPrivateNote',
  HAS_CREATED_SHARED_NOTE = 'hasCreatedSharedNote',
  HAS_EXPLORED_COHORT = 'hasExploredCohort',
  HAS_CONNECTED_CALENDAR = 'hasConnectedCalendar',
  HAS_LOGGED_IN = 'hasLoggedIn',
  // partner oriented
  HAS_MENTORING_PARTNER = 'hasMentoringPartner',
  HAS_COMPLETED_MENTORING_AGREEMENT = 'hasCompletedMentoringAgreement',
  HAS_MET_MENTORING_PARTNER = 'hasMetMentoringPartner',
  HAS_NEXT_MEETING_SCHEDULED = 'hasNextMeetingScheduled',
  HAS_COMPLETED_SURVEY = 'hasCompletedSurvey',
  HAS_WRAPPED_UP_RELATIONSHIP = 'hasWrappedUpRelationship',
}

export interface IRelatedActivityAttributes {
  partnerRoleId?: string;
  meetingId?: string;
  meetingStart?: number;
  goalId?: string;
  groupId?: string;
  trainingModule?: ETrainingModules;
  calendarType?: ECalendars;
  platform?: EPlatformTypes;
}

export interface IProgressActivity extends IRelatedActivityAttributes {
  completedTime: number; // epoch time
  programId?: string;
}

export interface IBaseProfileData {
  [EProfileAttributes.IMAGE]?: string;
  [EProfileAttributes.PHONE_NUMBER]?: string;
  [EProfileAttributes.FIRST_NAME]?: string;
  [EProfileAttributes.LAST_NAME]?: string;
  [EProfileAttributes.JOB_TITLE]?: string;
  [EProfileAttributes.ORGANISATION]?: string;
  [EProfileAttributes.LOCATION]?: string;
  [EProfileAttributes.CONTACT_EMAIL]?: string;
  [EProfileAttributes.LINKEDIN]?: string;
  [EProfileAttributes.CAREER_STORY]?: string;
  [EProfileAttributes.PREFERRED_MEETING_TIMES]?: string;
  [EProfileAttributes.PUBLIC_PROFILE]?: boolean;
  [EProfileAttributes.PUBLIC_PROFILE_RAW]?: boolean;
  [EProfileAttributes.ALLOW_PARTNER_VIEW_INSIGHTS]?: boolean;
  [EProfileAttributes.ALLOW_PARTNER_VIEW_INSIGHTS_RAW]?: string;
  [EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]?: { [programId: string]: number };
}

export interface IProfileData extends IBaseProfileData {
  completedProfile?: boolean;
  roleId?: string;
  newProfilePic?: string;
  profileType?: ProgramPositions;
  activityScore?: number;
  progressActivity?: { [attr in EProgressActivities]?: IProgressActivity[] };
}

export interface IProfileFormReducer {
  profile: IProfileData;
  pairProfile: IProfileData;
}

export const formReducerInitialState: IProfileFormReducer = {
  profile: { completedProfile: false },
  pairProfile: { completedProfile: false },
};
