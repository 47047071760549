import {
  EMoDTrainingModules,
  EStructuredTrainingModules,
} from '../../store/reducerTypes/TrainingReducer.types';
import {
  YouTubeFeedbackGoneAwryVideoId,
  YouTubeMasterFeedbackVideoId,
} from '../../consts/YouTubeVideoIds';
import { IResource } from './Resources';

export enum EResourceType {
  LINK = 'LINK',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export const resources: {
  [EStructuredTrainingModules.INTRODUCTION_MENTEE]: IResource[];
  [EStructuredTrainingModules.INTRODUCTION_MENTOR]?: IResource[];
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTEE]: IResource[];
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTOR]: IResource[];
  [EStructuredTrainingModules.BE_GREAT_MENTEE]: IResource[];
  [EStructuredTrainingModules.BE_GREAT_MENTOR]: IResource[];
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE]: IResource[];
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR]: IResource[];
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE]: IResource[];
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]: IResource[];
  // MoD
  [EMoDTrainingModules.MOD_BE_GREAT_MENTEE]: IResource[];
  [EMoDTrainingModules.MOD_BE_GREAT_MENTOR]: IResource[];
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE]: IResource[];
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]: IResource[];
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE]: IResource[];
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR]: IResource[];
  [EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR]: IResource[];
  // [EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR]: IResource[],
} = {
  [EMoDTrainingModules.MOD_BE_GREAT_MENTEE]: [
    {
      src: 'tips_for_your_first_mentoring_meeting.pdf',
      title: 'Tips for your first meeting',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'mentor_vs_mentee_responsibilities.pdf',
      title: 'Mentor vs Mentee responsibilities',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'mentoring-guide.pdf',
      title: 'Mentoring Guide',
      isSharedResource: true,
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_BE_GREAT_MENTOR]: [
    {
      src: 'mentoring-guide.pdf',
      title: 'Mentoring Guide',
      isSharedResource: true,
      resourceType: EResourceType.FILE,
    },
    {
      src: 'mentor_vs_mentee_responsibilities.pdf',
      title: 'Mentor vs Mentee responsibilities',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'tips_for_your_first_mentoring_meeting.pdf',
      title: 'Tips for your first meeting',
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE]: [
    {
      src: 'SMART_Goals_Infographic.pdf',
      title: 'SMART Goals Cheat Sheet',
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]: [
    {
      src: 'SMART_Goals_Infographic.pdf',
      title: 'SMART Goals Cheat Sheet',
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE]: [
    {
      src: 'Kolbs_Reflection_Model.pdf',
      title: "Kolb's Reflection model",
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR]: [
    {
      src: 'Kolbs_Reflection_Model.pdf',
      title: "Kolb's Reflection model",
      resourceType: EResourceType.FILE,
    },
  ],
  [EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR]: [
    {
      src: 'GROWTH_framework.pdf',
      title: 'Coaching GROWTH Framework',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'situational_mentorship_guide.pdf',
      title: 'Situational Mentorship Guide',
      resourceType: EResourceType.FILE,
    },
  ],
  introductionMentee: [
    {
      src: 'tips_for_your_first_mentoring_meeting.pdf',
      title: 'Tips for your first meeting',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'cognitive_bias.pdf',
      title: 'Cognitive Bias',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'mentor_vs_mentee_responsibilities.pdf',
      title: 'Mentor vs Mentee responsibilities',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'mentoring-guide.pdf',
      title: 'Mentoring Guide',
      isSharedResource: true,
      resourceType: EResourceType.FILE,
    },
  ],
  introductionMentor: [
    {
      src: 'mentoring-guide.pdf',
      title: 'Mentoring Guide',
      isSharedResource: true,
      resourceType: EResourceType.FILE,
    },
  ],
  makeTheMostMentee: [
    {
      src: 'Kolb_learning_model.pdf',
      title: "Kolb's Model of Adult Learning (1984)",
      resourceType: EResourceType.FILE,
    },
  ],
  makeTheMostMentor: [
    {
      src: 'Kolb_learning_model.pdf',
      title: 'Kolb learning model',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'kolbModel.png',
      title: 'Kolb Model infographic',
      resourceType: EResourceType.FILE,
    },
  ],
  beGreatMentee: [
    {
      src: 'fixed_vs_growth_mindset.pdf',
      title: 'Fixed vs Growth mindset',
      resourceType: EResourceType.FILE,
    },
  ],
  beGreatMentor: [
    {
      src: 'Directive_vs_coaching.pdf',
      title: 'Director or coach?',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'GROWTH_framework.pdf',
      title: 'GROWTH Framework',
      resourceType: EResourceType.FILE,
    },
  ],
  givingReceivingFeedbackMentee: [
    {
      src: 'receiving_feedback_effectively.pdf',
      title: '9 steps to receiving feedback',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Emotional_intelligence.pdf',
      title: 'Emotional intelligence',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Emotional_support.pdf',
      title: 'Emotional support',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'giving_feedback.pdf',
      title: 'Giving feedback',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Master_feedback_model.pdf',
      title: 'Master feedback model',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'receiving_feedback_effectively.pdf',
      title: 'Receiving feedback effectively',
      resourceType: EResourceType.FILE,
    },
    {
      src: YouTubeFeedbackGoneAwryVideoId,
      title: 'Feedback Gone Awry video',
      isYouTube: true,
      resourceType: EResourceType.FILE,
    },
    {
      src: YouTubeMasterFeedbackVideoId,
      title: 'Master Feedback Conversation video',
      isYouTube: true,
      resourceType: EResourceType.FILE,
    },
    {
      src: 'cognitive_bias.pdf',
      title: 'Cognitive Bias',
      resourceType: EResourceType.FILE,
    },
  ],
  givingReceivingFeedbackMentor: [
    {
      src: 'receiving_feedback_effectively.pdf',
      title: '9 steps to receiving feedback',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Emotional_intelligence.pdf',
      title: 'Emotional intelligence',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Emotional_support.pdf',
      title: 'Emotional support',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'giving_feedback.pdf',
      title: 'Giving feedback',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'Master_feedback_model.pdf',
      title: 'Master feedback model',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'receiving_feedback_effectively.pdf',
      title: 'Receiving feedback effectively',
      resourceType: EResourceType.FILE,
    },
    {
      src: YouTubeMasterFeedbackVideoId,
      title: 'Feedback Gone Awry video',
      isYouTube: true,
      resourceType: EResourceType.FILE,
    },
    {
      src: YouTubeMasterFeedbackVideoId,
      title: 'Master Feedback Conversation video',
      isYouTube: true,
      resourceType: EResourceType.FILE,
    },
    {
      src: 'cognitive_bias.pdf',
      title: 'Cognitive Bias',
      resourceType: EResourceType.FILE,
    },
  ],
  beyondProgramMentee: [
    {
      src: 'ending_relationship_mentee.pdf',
      title: 'Ending a mentoring relationship',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'continuing_relationship_mentee.pdf',
      title: 'Continuing a mentoring relationship infographic',
      resourceType: EResourceType.FILE,
    },
  ],
  beyondProgramMentor: [
    {
      src: 'ending_relationship_mentor.pdf',
      title: 'Ending a mentoring relationship',
      resourceType: EResourceType.FILE,
    },
    {
      src: 'continuing_relationship_mentor.pdf',
      title: 'Continuing a mentoring relationship infographic',
      resourceType: EResourceType.FILE,
    },
  ],
};

export const mentoringConcernResources: IResource[] = [
  {
    src: 'ending_a_relationship.pdf',
    title: 'Ending a relationship',
    resourceType: EResourceType.FILE,
  },
  {
    src: 'Mentoring_pair_concerns.pdf',
    title: 'Mentoring pair concerns',
    resourceType: EResourceType.FILE,
  },
];

export const generalResources: IResource[] = [
  {
    src: 'https://askizzy.org.au',
    title: 'Ask Izzy - Find the help you need',
    resourceType: EResourceType.LINK,
  },
  {
    src: 'Conversation_Starters.pdf',
    title: 'Conversation starters',
    resourceType: EResourceType.FILE,
  },
];

export const platformUsageResources: IResource[] = [
  {
    src: 'https://scribehow.com/shared/Edit_Sign_Up_Matching_Form__pUwITOjSQrS3os6394Mw5g',
    title: 'Edit Your Sign Up/Matching Form',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/How_to_Integrate_Your_Calendar_on_Branchers_Mentoring_Platform__vbb4NKA3TXOs3wrR-gyyuA?referrer=documents',
    title: 'Integrate your calendar',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/Edit_your_profile__kgk52dOWTFK0L40qGXceMQ?referrer=documents',
    title: 'Edit your profile',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/How_To_Access_Freddie_Fentons_Profile__-krmwsAkTyWaUiqiSSrDKQ',
    title: 'View match contact details and profile',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/Create_New_Meeting_with_calendar_integration__poqHJ1RDTNKdgyJ1TiPkng?referrer=documents',
    title: 'Create New Meeting (with calendar integration)',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/Create_New_Meeting_without_calendar_integration__ZfJpS9hZTMiDtnAO62Y0mQ?referrer=documents',
    title: 'Create New Meeting (without calendar integration)',
    resourceType: EResourceType.LINK,
  },
  {
    src:
      'https://scribehow.com/shared/Access_and_select_mentoring_topics_on_Brancher_platform__Pvs1DeJZSpSJnAoBVUhU3Q',
    title: 'Using filters on the find a mentoring partner page',
    resourceType: EResourceType.LINK,
  },
];

export const groupResources: IResource[] = [
  {
    src: 'Ice_Breakers_for_Group_Mentoring.pdf',
    title: 'Ice Breakers for Group Mentoring',
    isGroupResource: true,
    resourceType: EResourceType.FILE,
  },
  {
    src: 'Introduction_to_Group_Mentoring.pdf',
    title: 'Introduction to Group Mentoring',
    isGroupResource: true,
    resourceType: EResourceType.FILE,
  },
  {
    src: 'Myths_tips_and_tricks_for_Group_Mentoring.pdf',
    title: 'Myths tips and tricks for Group Mentoring',
    isGroupResource: true,
    resourceType: EResourceType.FILE,
  },
  {
    src: 'Structure_and_Topics_for_Group_Mentoring_Discussions.pdf',
    title: 'Structure and Topics for Group Mentoring Discussions',
    isGroupResource: true,
    resourceType: EResourceType.FILE,
  },
];
