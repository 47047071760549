import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { Colors } from '../../consts/colors';
import { Text } from '../../Components/General/Text';
import { EValues, ValuesBrancherNames } from '../../Form/Values/ValuesFormConfig';
import { IValuesPersonalityAttributeInsights } from '../../store/reducers/UserInfoReducer';
import capitalize from '@material-ui/core/utils/capitalize';

const useStyles = makeStyles({
  insightsImage: {
    width: '100%',
    maxHeight: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 600 : 300),
    height: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 500 : 300),
    borderRadius: `15px 0px 0px 15px`,
  },
  attributeInsights: {
    padding: 30,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  flatCard: {
    maxHeight: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 600 : 300),
    height: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 500 : 300),
    marginBottom: 20,
    borderRadius: 15,
    background: Colors.backgroundDarkGrey,
  },
  pill: {
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    borderRadius: 15,
    height: 44,
    background: Colors.white,
  },
  pillDecorator: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: Colors.purple,
    display: 'inline',
    marginRight: 8,
    color: Colors.white,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 16,
  },
});

export enum EMatchInsightType {
  PERSONALITY = 'personality',
  VALUES = 'values',
}

interface ISimpleMatchInsights {
  insightType: EMatchInsightType;
  insights: IValuesPersonalityAttributeInsights[];
}

export const SimpleMatchInsights: React.FC<ISimpleMatchInsights> = (props) => {
  const { insights, insightType } = props;
  const phablet = useMediaQuery(useTheme().breakpoints.down('md'));
  const styles = useStyles({ phablet });

  const isPersonalityType = insightType === EMatchInsightType.PERSONALITY;
  const topInsights: string[] = insights
    .map((i) => ({
      attributeName: i.attributeName,
      value: Math.abs(i.menteeValue - i.mentorValue),
    }))
    .sort((a, b) => (a.value >= b.value ? 1 : -1))
    .slice(0, 3)
    .map((b) => b.attributeName);

  const simpleContentsMapping = {
    personality: {
      image: 'simpleMatchPersonality.png',
    },
    values: {
      image: 'simpleMatchValues.png',
    },
  };

  const insightsImage = GetIllustrationLink(simpleContentsMapping[insightType].image);

  return (
    <Grid container justify="center" item xs={12} className={styles.flatCard}>
      <Grid container item xs={5} md={4}>
        <Grid container item xs={12}>
          <img src={insightsImage} alt={insightType} className={styles.insightsImage} />
        </Grid>
      </Grid>
      <Grid container item xs={7} md={8} className={styles.attributeInsights}>
        <Grid item xs={12} container spacing={2} alignItems="center">
          {topInsights.map((att, attrIndex) => (
            <InsightPill
              insight={att}
              isPersonality={isPersonalityType}
              insightIndex={attrIndex + 1}
              key={attrIndex}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

interface IInsightPill {
  insight: string;
  isPersonality: boolean;
  insightIndex?: number;
}

const InsightPill: React.FC<IInsightPill> = ({ insight, isPersonality, insightIndex }) => {
  const styles = useStyles();

  const PillMapping: { [x in EValues]: string } = {
    [EValues.ACH]: '1',
    [EValues.POW]: '2',
    [EValues.BEN]: '3',
    [EValues.HED]: '4',
    [EValues.UNI]: '5',
    [EValues.SEC]: '6',
    [EValues.TRA]: '7',
    [EValues.STI]: '8',
    [EValues.SD]: '9',
    [EValues.CON]: '10',
  };

  const actualReadableInsight = isPersonality ? capitalize(insight) : ValuesBrancherNames[insight];

  return (
    <Grid item className={styles.pill} xs={12} container alignItems="center">
      <Box className={styles.pillDecorator}>{insightIndex}</Box>
      <Text variant="sm" fontWeight={700} display="inline">
        {actualReadableInsight}
      </Text>
    </Grid>
  );
};
