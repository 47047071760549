export const Colors: { [key: string]: string } = {
  purple: '#5000FF',
  darkPurple: '#2A0134',
  lightPurple: '#A880FF',
  backgroundLightPurple: '#F9F9FC',
  backgroundDarkPurple: '#E1E7F8',
  backgroundDarkerPurple: '#E4F6FF',
  backgroundLightBlue: '#AEE9F2',
  backgroundDarkBlue: '#53EFFC',
  backgroundDarkerBlue: '#33CCFF',
  darkBlue: '#4E75F4',
  lightGrey: '#D1D1D1',
  disabledGrey: '#DEDFE2',
  darkGrey: '#888888',
  chartLines: '#B9B9BA',
  tertiaryGrey: '#607177',
  secondaryGrey: '#3C3C3E',
  placeholderGrey: '#8B8B8B',
  scaleGrey: '#CCCCCC',
  backgroundGrey: '#F2F2F2',
  backgroundDarkGrey: '#F6F6F6',
  cardBorder: '#D8DCE9',
  informational: '#FFFEDB',
  red: '#FF0000',
  actionRed: '#F50057',
  darkRed: '#8B0000',
  lightRed: '#FFCCCC',
  green: '#06A846',
  emerald: '#43C59E',
  lightGreen: '#32CD32',
  orange: '#FFA500',
  CTABanner: '#DBFAFF',
  incorrect: '#FFECEC',
  correct: '#DBFFED',
  white: '#FFF',
  black: '#000',
  transparent: 'transparent',
};

export type IColors =
  | 'purple'
  | 'lightPurple'
  | 'backgroundLightPurple'
  | 'backgroundDarkPurple'
  | 'backgroundDarkerPurple'
  | 'backgroundLightBlue'
  | 'backgroundDarkBlue'
  | 'backgroundDarkerBlue'
  | 'informational'
  | 'lightGrey'
  | 'disabledGrey'
  | 'darkGrey'
  | 'tertiaryGrey'
  | 'secondaryGrey'
  | 'placeholderGrey'
  | 'darkBlue'
  | 'backgroundGrey'
  | 'chartLines'
  | 'cardBorder'
  | 'red'
  | 'actionRed'
  | 'darkRed'
  | 'lightRed'
  | 'orange'
  | 'green'
  | 'emerald'
  | 'lightGreen'
  | 'CTABanner'
  | 'incorrect'
  | 'correct'
  | 'transparent'
  | 'white'
  | 'black';
