import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import Launch from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { EInsights, IAttributeInsights } from '../../store/reducers/UserInfoReducer';
import { Colors } from '../../consts/colors';
import { BrancherList } from '../../Components/General/BrancherList';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { BrancherDispatch, UtilRequestUnlockInsights } from '../../store/actions';

const useStyles = makeStyles({
  insightsCard: {
    borderRadius: 25,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    padding: 0,
    maxHeight: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 640 : 320),
    minHeight: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 325 : 150),
  },
  insightsImage: {
    width: '100%',
    maxHeight: (props: { showInsight: boolean; phablet: boolean }) => (props.phablet ? 640 : 320),
    borderRadius: `15px 0px 0px 15px`,
  },
  popOutInsightsImage: {
    width: '100%',
    maxHeight: 400,
    height: 400,
  },
  moreInfo: {
    borderRadius: '50%',
    backgroundColor: Colors.purple,
    width: 24,
    height: 24,
  },
  attributeInsights: {
    padding: 30,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popOutAttributeInsights: {
    padding: 30,
    marginTop: 10,
  },
  attributeInsight: {
    filter: (props: { showInsight: boolean; phablet: boolean }) =>
      !props.showInsight && 'blur(4px)',
  },
  readMore: {
    background: Colors.backgroundDarkGrey,
    borderRadius: 8,
    padding: 8,
  },
});

const insightsContentMapping: {
  [x in EInsights]: {
    image: string;
    title: string;
    subtitle: string;
    showReadMore?: boolean;
    noShow?: string;
    showInfo?: boolean;
    description?: string;
  };
} = {
  [EInsights.STRENGTHS]: {
    image: 'strengths.png',
    title: 'Strengths',
    subtitle: 'What you do best',
    showReadMore: true,
    description:
      'These are the natural behaviour strengths based on your strongest styles. Leverage these strengths in key situations to boost your effectiveness and impact.',
  },
  [EInsights.WATCHOUTS]: {
    image: 'watchouts.png',
    title: 'Watch outs',
    subtitle: 'Potential blindspots',
    showReadMore: true,
    description:
      'These are areas opposite to your strongest styles and often less developed. Proactively manage these to mitigate their impact on your effectiveness.',
  },
  [EInsights.MOTIVATORS]: {
    image: 'motivators.png',
    title: 'Motivators',
    subtitle: 'Things that energise you',
    showInfo: true,
    description:
      'These are your strongest motivators. Look for opportunities to align with these motivators to increase your sense of purpose, satisfaction and engagement.',
  },
  [EInsights.DRAINERS]: {
    image: 'drainers.png',
    title: 'Drainers',
    subtitle: 'Things that frustrate, stress or bore you',
    showInfo: true,
    description:
      'These are triggers that can cause stress or frustration. Learn to recognise triggers to better handle or change situations that deplete your energy or cause conflict.',
  },
  [EInsights.SHARED_STRENGTHS]: {
    image: 'sharedStrengths.png',
    title: 'Shared Strengths',
    subtitle: 'Where we are similar',
    showInfo: true,
    description:
      'These are the qualities and skills you both excel in. Recognising these common strengths can help build trust, collaboration, and effective interactions.',
    noShow:
      'You and your match bring different strengths to the table, with no major similarities in this area. Use the conversation starters below to explore how your unique approaches can complement each other.',
  },
  [EInsights.VARYING_STYLES]: {
    image: 'varyingValueStyles.png',
    title: 'Varying Styles',
    subtitle: 'Where we differ',
    showInfo: true,
    description:
      'These differences are potential triggers that can cause communication issues, difference in opinion, stress, frustration or lost interest. Seek to understand your differences and what you can learn from each other.',
    noShow:
      'You and your match share similar styles, with no major differences to highlight. Use the conversation starters below to explore how your common approaches can strengthen your connection.',
  },
  [EInsights.SHARED_MOTIVATORS]: {
    image: 'sharedMotivators.png',
    title: 'Shared Motivators',
    subtitle: 'Where we are similar',
    showInfo: true,
    description:
      'These are the values and drivers that energise and inspire both of you. Understanding what fuels your motivation can create alignment and make your interactions more engaging and purposeful.',
    noShow:
      'You and your match have different motivators, with no major similarities in values to highlight. Use the conversation starters below to explore what drives each of you and how your perspectives can complement one another.',
  },
  [EInsights.VARYING_VALUES]: {
    image: 'varyingValueStyles.png',
    title: 'Varying Values',
    subtitle: 'Where we differ',
    showInfo: true,
    description:
      'These differences are potential triggers that can cause communication issues, difference in opinion, stress, frustration or lost interest. Seek to understand your differences and what you can learn from each other.',
    noShow:
      'You and your match share similar values, with no major differences to highlight. Use the conversation starters below to explore how your aligned values can strengthen your connection.',
  },
};

const paidInsights = [
  EInsights.DRAINERS,
  EInsights.WATCHOUTS,
  EInsights.VARYING_STYLES,
  EInsights.VARYING_VALUES,
];

export const InsightsCard: React.FC<IAttributeInsights> = (props) => {
  const { insights, insightType, menteeInsights, mentorInsights } = props;
  const sesPos = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const programId = useSelector((state: IStoreTypes) => state.user?.programId);
  const insightsUnlockRequest = useSelector(
    (state: IStoreTypes) => state.user?.insightsUnlockRequest,
  );
  const sesPosInsights = sesPos === ProgramPositions.mentee ? menteeInsights : mentorInsights;
  const betweenPhablet = useMediaQuery(useTheme().breakpoints.between('md', 'xl'));
  const phablet = useMediaQuery(useTheme().breakpoints.down('md'));
  const [openReadMore, setOpenReadMore] = React.useState<boolean>(false);
  const [openRequestUnlock, setOpenRequestUnlock] = React.useState<boolean>(false);
  const [requestingUnlock, setRequestingUnlock] = React.useState<boolean>(false);
  const [openTileDescription, setOpenTileDescription] = React.useState(null);
  const dispatch = useDispatch();

  const setSendOpenRequestUnlock = () => {
    setRequestingUnlock(true);
    BrancherDispatch(
      dispatch,
      UtilRequestUnlockInsights(() => {
        setOpenRequestUnlock(false);
        setRequestingUnlock(false);
      }),
    );
  };

  const paid = useSelector((state: IStoreTypes) => !!state.user?.paidInsights);
  const isPaidInsight = paidInsights.includes(insightType);
  const showInsight = (paid && isPaidInsight) || !isPaidInsight;
  const styles = useStyles({ showInsight, phablet });

  const isPairInsights = !!menteeInsights && !!mentorInsights;
  const actualInsights = isPairInsights
    ? EInsights.VARYING_STYLES === insightType || EInsights.SHARED_STRENGTHS === insightType
      ? sesPosInsights.slice(0, 2)
      : sesPosInsights.slice(0, 3)
    : showInsight
    ? insights.slice(0, 3)
    : insights;

  const title = insightsContentMapping[insightType].title;
  const subtitle = insightsContentMapping[insightType].subtitle;
  const description = insightsContentMapping[insightType]?.description;
  const insightsImage = GetIllustrationLink(insightsContentMapping[insightType].image);
  const showReadMore = insightsContentMapping[insightType]?.showReadMore;
  const showInfo = insightsContentMapping[insightType]?.showInfo;

  // @ts-ignore
  const listItems: string[] = actualInsights.map((a) => (typeof a === 'string' ? a : a.short));
  const noDifferences = listItems?.length === 0;

  return (
    <Grid container justify="center" item xs={12} className={styles.insightsCard}>
      <BrancherDialog
        setClose={() => setOpenReadMore(false)}
        open={openReadMore}
        labelledBy={`${title} ${subtitle}`}
        contentWidth
        ignoreLeftPaddingMargin
      >
        <InsightsPopOut
          insights={actualInsights}
          title={title}
          subtitle={subtitle}
          insightsImage={insightsImage}
          insightType={insightType}
          showInsight={showInsight}
        />
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenTileDescription(false)}
        open={openTileDescription}
        labelledBy={title}
        contentWidth
      >
        <Grid container item xs={12}>
          <Text variant="md" fontWeight={400}>
            {description}
          </Text>
        </Grid>
      </BrancherDialog>
      <BrancherDialog
        setClose={() => setOpenRequestUnlock(false)}
        open={openRequestUnlock}
        labelledBy={`${title} ${subtitle}`}
        contentWidth
        title="Would you like to 'Request to Unlock' this insight?"
      >
        <Grid container justify="center">
          <Grid container item>
            <Text variant="md" marginBottom={50} marginTop={20}>
              This will notify your program administrator that you would like to see this insight.
            </Text>
          </Grid>
          <Grid item xs={12} md={8} container justify="space-between">
            <RejectButton disabled={requestingUnlock} onClick={() => setOpenRequestUnlock(false)}>
              No
            </RejectButton>
            <AcceptButton disabled={requestingUnlock} onClick={setSendOpenRequestUnlock}>
              Yes
            </AcceptButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid container item xs={3}>
        <Grid container item xs={12}>
          <img src={insightsImage} alt={insightType} className={styles.insightsImage} />
        </Grid>
      </Grid>
      <Grid container item xs={9} className={styles.attributeInsights}>
        <Grid item xs={12}>
          <Grid item container justify="space-between" xs={12} alignItems="center">
            <Grid item>
              <Text variant="xl" fontWeight={600}>
                {title}:
              </Text>
            </Grid>
            <Grid item>
              {showReadMore && showInsight ? (
                <Grid
                  item
                  alignItems="center"
                  container
                  className={styles.readMore}
                  onClick={() => setOpenReadMore(showInsight)}
                >
                  <Text
                    variant="xxs"
                    display="inline"
                    fontWeight={600}
                    color="purple"
                    marginRight={8}
                  >
                    Read More
                  </Text>
                  <Launch />
                </Grid>
              ) : showInfo && showInsight ? (
                <Grid item alignItems="center" container>
                  <IconButton onClick={() => setOpenTileDescription(true)}>
                    <Info fontSize="large" />
                  </IconButton>
                </Grid>
              ) : (
                (showReadMore || showInfo) &&
                !showInsight &&
                (!insightsUnlockRequest?.[programId]?.[sessionRoleId] ? (
                  <Grid item alignItems="center" container>
                    <BrancherLinkText
                      onClick={() => setOpenRequestUnlock(true)}
                      underline="always"
                      variant="xxs"
                      fontWeight={600}
                      color="purple"
                    >
                      Request to Unlock
                    </BrancherLinkText>
                  </Grid>
                ) : (
                  <Grid item>
                    <Text variant="xxs" fontWeight={600} color="purple">
                      Insights requested
                    </Text>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Text variant="xxs" fontWeight={600} color="placeholderGrey">
              {subtitle}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.attributeInsight}>
          {noDifferences ? (
            <Text variant="sm" marginTop={15}>
              {insightsContentMapping[insightType].noShow}
            </Text>
          ) : (
            <BrancherList
              listItems={listItems}
              variant={betweenPhablet ? 'xxs' : 'xs'}
              fontWeight={500}
              marginBottom={30}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

interface IInsightsPopOut {
  insights: IAttributeInsights['insights'] | IAttributeInsights['menteeInsights'];
  insightType: EInsights;
  insightsImage: string;
  title: string;
  subtitle: string;
  showInsight: boolean;
}

const InsightsPopOut: React.FC<IInsightsPopOut> = (props) => {
  const { insights, insightType, insightsImage, showInsight, subtitle, title } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ showInsight, mobile });

  return (
    <Grid container alignItems="center">
      <Grid container item xs={3}>
        <Grid container item xs={12}>
          <img src={insightsImage} alt={insightType} className={styles.popOutInsightsImage} />
        </Grid>
      </Grid>
      <Grid container item xs={9} className={styles.popOutAttributeInsights}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Text variant="xl" fontWeight={600}>
              {title}: {subtitle}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BrancherList
            listItems={(insights as any[])?.map((a) => (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Text variant="sm" fontWeight={600} marginTop={20}>
                    {typeof a === 'string' ? a : a?.short}
                  </Text>
                </Grid>
                {a?.long && (
                  <Grid item xs={12}>
                    <Text marginTop={10} variant="xs" fontWeight={400}>
                      {a.long}
                    </Text>
                  </Grid>
                )}
              </Grid>
            ))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
