import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Colors } from '../consts/colors';

const breakpoints = createMuiTheme().breakpoints;

export const MuiDialogContent = (brandPrimary: string) => ({
  root: {
    [breakpoints.down('sm')]: {
      margin: '20px 10px',
      padding: '4px 12px',
      '--webkit-overflow-scrolling': 'touch',
      '--ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        background: 'transparent',
      },
    },
    [breakpoints.up('sm')]: {
      margin: '30px 15px',
      padding: '8px 20px',
      '&::-webkit-scrollbar': {
        width: 8,
      },
      '&::-webkit-scrollbar-track': {
        background: Colors.backgroundDarkPurple,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: brandPrimary,
        borderRadius: 10,
        border: `3px solid transparent`,
      },
    },
    '&:first-child': {
      paddingTop: 8,
    },
    overflowX: 'hidden',
  },
});
