import { QUALIFY_CLEAR_FORM_DATA, QUALIFY_FORM_DATA } from '../reducers/QualifyingReducer';
import { ProgramRoleIds } from '../reducers/UserInfoReducer';
import { ClearValuesFormData, SaveValuesFormData } from './ValuesActions';
import { ClearGeneralFormData, SaveGeneralFormData } from './GeneralFormActions';
import { ClearPersonalityFormData, SavePersonalityFormData } from './PersonalityActions';
import { ClearFinalisingFormData, SaveFinalisingFormData } from './FinalisingActions';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest } from './utils/api-utils';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { SaveUserInfo } from './UserInfoActions';
import { IGeneralFormConfig } from '../../Form/General/GeneralFormConfig';
import { IFinalisingFormConfig } from '../../Form/Finalising/FinalisingFormConfig';

export const SaveQualifyFormData = (userData: object) => {
  return {
    type: QUALIFY_FORM_DATA,
    payload: userData,
  };
};

export const ClearQualifyingFormData = () => {
  return {
    type: QUALIFY_CLEAR_FORM_DATA,
  };
};

export const ClearFormData = () => {
  return (dispatch: any) => {
    dispatch(ClearQualifyingFormData());
    dispatch(ClearGeneralFormData());
    dispatch(ClearValuesFormData());
    dispatch(ClearPersonalityFormData());
    dispatch(ClearFinalisingFormData());
  };
};

interface IUtilGetForm extends IAPIResponseObject {
  data: {
    step?: number;
  };
}

export const UtilGetFormData = (position: ProgramPositions | '', cb: (a: IUtilGetForm) => void) => {
  return (dispatch: any, getState: any) => {
    const roleId =
      position === ProgramPositions.mentee
        ? getState().user.menteeRoleId
        : getState().user.mentorRoleId;
    const programId = getState().user.programId;
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/form',
        params: JSON.stringify({
          roleId,
          programId,
          position,
          username,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const responseData = response.data.data.Item;
        if (responseData) {
          const { step, formData = {} } = responseData;
          const {
            qualifying = {},
            general = {},
            values = {},
            personality = {},
            finalising = {},
          } = formData;
          const { configValues: cvg, ...otherGeneral } = general;
          const { configValues: cvv, response: pvv, ...otherValues } = values;
          const { configValues: cvp, ...otherPersonality } = personality;
          const { configValues: cvf, ...otherFinalising } = finalising;
          if (response?.data?.data?.prepopulate) {
            const finalisingPrepopulateValues = { ...otherFinalising, submitted: false };
            dispatch(SaveGeneralFormData({ ...otherGeneral, ...finalisingPrepopulateValues }));
            // TODO: configValues should perhaps be ignored, as when we want to update the values, we may want different responses...
            // So it would only be response: pvv, ...otherValues, to test
            dispatch(SaveValuesFormData(values));
            dispatch(SavePersonalityFormData(otherPersonality));
            dispatch(SaveFinalisingFormData(finalisingPrepopulateValues));
            cb({ success: true, data: {} });
          } else {
            // If the form config has been updated to use valuesV2 and we apply an old form response to it, ignore it
            const savedValuesConfigVersion = getState().user.formConfig.values?.version;
            const ignorePreviousValuesFormRes =
              savedValuesConfigVersion === 'v2' && !!cvv?.[0]?.key;
            const newValuesFormRes = { ...otherValues, configValues: cvv, response: pvv };
            dispatch(SaveQualifyFormData({ ...qualifying, step }));
            dispatch(SaveGeneralFormData({ ...otherGeneral, ...otherFinalising }));
            if (!ignorePreviousValuesFormRes) {
              dispatch(SaveValuesFormData(newValuesFormRes));
            }
            dispatch(SavePersonalityFormData(otherPersonality));
            dispatch(SaveFinalisingFormData(otherFinalising));
            cb({ success: true, data: { step } });
          }
        } else {
          const newRoleId = response?.data?.data.newRoleId;
          const roleAttribute =
            position === ProgramPositions.mentee
              ? ProgramRoleIds.mentee
              : position === ProgramPositions.mentor
              ? ProgramRoleIds.mentor
              : position === ProgramPositions.peer
              ? ProgramRoleIds.peer
              : ProgramRoleIds.catchups;
          if (!!newRoleId) {
            dispatch(SaveUserInfo({ sessionRoleId: newRoleId, [roleAttribute]: newRoleId }));
          }
          // This is in the case that they have never entered the platform, so they need default values set
          let general = {};
          if (position !== ProgramPositions.catchups) {
            getState()?.general?.configValues.mentee?.forEach((a) => (general[a.key] = a.value));
            getState()?.general?.configValues.mentor?.forEach((a) => (general[a.key] = a.value));
          } else {
            getState()?.general.configValues.forEach((a) => (general[a.key] = a.value));
          }
          let values = {};
          getState()?.values?.configValues?.forEach((a) => (values[a.key] = a.value));
          let personality = {};
          getState()?.personality?.configValues?.forEach((a) => (personality[a.key] = a.value));
          let finalising = {};
          getState()?.finalising?.configValues?.forEach((a) => (finalising[a.key] = a.value));
          dispatch(SaveGeneralFormData(general));
          dispatch(SaveValuesFormData(values));
          dispatch(SavePersonalityFormData(personality));
          dispatch(SaveFinalisingFormData(finalising));
          cb({ success: true, data: {} });
        }
      })
      .catch((error) => {});
  };
};

// This gets all the config data and default form values
export const UtilGetFormConfiguration = (
  position: ProgramPositions | '',
  ignoreValues: boolean,
  cb: (a?: {
    formConfig: { general: IGeneralFormConfig[]; finalising: IFinalisingFormConfig[] };
    formConfigDefaultValues: any[];
  }) => void,
) => {
  return (dispatch: any, getState: any) => {
    const formId = getState().user.formId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/formconfiguration',
        params: JSON.stringify({
          formId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const { formConfig, formConfigDefaultValues = {} } = response.data.data;
        if (!ignoreValues) {
          const {
            general = {},
            values = {},
            personality = {},
            finalising = {},
          } = formConfigDefaultValues;
          dispatch(SaveUserInfo({ formConfig }));
          dispatch(SaveGeneralFormData({ configValues: general }));
          dispatch(SaveValuesFormData({ configValues: values, response: values }));
          dispatch(SavePersonalityFormData({ configValues: personality }));
          dispatch(SaveFinalisingFormData({ configValues: finalising }));
        }
        cb({ formConfig, formConfigDefaultValues });
      })
      .catch((error) => {
        cb(error);
      });
  };
};
