import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { Colors } from '../../../consts/colors';
import { Text } from '../../General/Text';
import { IBrancherToggleButton, IBrancherToggleButtonBase } from './BrancherToggleButton.types';
import {
  BrancherButton,
  EditButton,
  SearchLetterButton,
  SearchNavigationButton,
} from '../BrancherButton';
import { BrancherList } from '../../General/BrancherList';
import { BrancherDivider } from '../../General/BrancherDivider';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../../store/storeTypes';

const useStyles = makeStyles({
  radioIcon: {
    color: (props: { primaryColor: string }) => props.primaryColor,
    background: Colors.transparent,
  },
  checkboxIcon: {
    color: (props: { primaryColor: string }) => props.primaryColor,
    background: Colors.transparent,
    fontSize: (props: IBrancherToggleButtonBase) => (props.selected ? 20 : 24),
  },
  checkIcon: {
    color: (props: { primaryColor: string }) => props.primaryColor,
    background: Colors.transparent,
    fontSize: 14,
  },
  halfWidth: {
    marginRight: (props: IBrancherToggleButtonBase) => props.thirdWidth && 19,
    minWidth: (props: IBrancherToggleButtonBase) =>
      props.halfWidth
        ? 'calc((100% - 38px)/2)'
        : props.thirdWidth
        ? 'calc((100% - 57px)/3)'
        : '100%',
    width: (props: IBrancherToggleButtonBase) =>
      props.halfWidth
        ? 'calc((100% - 38px)/2)'
        : props.thirdWidth
        ? 'calc((100% - 57px)/3)'
        : '100%',
  },
  halfWidthGroup: {
    minWidth: '100%',
    width: '100%',
    flexWrap: 'wrap', // Making my own Grid
    flexDirection: (props: IBrancherToggleButton) =>
      props.halfWidth || props.thirdWidth ? 'row' : 'column',
    justifyContent: (props: IBrancherToggleButton) => props.halfWidth && 'space-between',
  },
});

const BrancherToggleButtonBase = (props: IBrancherToggleButtonBase) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ ...props, primaryColor });
  const { label, selected, exclusive, halfWidth, thirdWidth, ...other } = props;
  return (
    <ToggleButton selected={selected} classes={{ root: styles.halfWidth }} {...other}>
      {exclusive && selected ? (
        <RadioButtonChecked className={styles.radioIcon} />
      ) : exclusive ? (
        <RadioButtonUnchecked className={styles.radioIcon} />
      ) : selected ? (
        <CheckBox className={styles.checkboxIcon} viewBox="2 2 20 20" />
      ) : (
        <CheckBoxOutlineBlank className={styles.checkboxIcon} />
      )}
      <Text variant="sm" color={selected ? 'purple' : 'black'} marginLeft={20}>
        {label}
      </Text>
    </ToggleButton>
  );
};

export const BrancherToggleButton = (props: IBrancherToggleButton) => {
  const [open, setOpen] = React.useState(false);
  const [openEditDialog, setEditOpen] = React.useState(false);
  const styles = useStyles(props);

  const {
    value,
    updateValue,
    exclusive,
    name,
    options,
    halfWidth,
    thirdWidth,
    searchable,
    editToggle,
    editToggleQuestion,
    maximumAmount = options.length,
    hideOptions = [],
  } = props;

  // Works out when to pass values back up based upon maximumAmount passed in
  const handleChange = (e, newValue) => {
    if ((!exclusive && newValue.length <= maximumAmount) || exclusive) {
      updateValue(newValue);
    }
  };

  // Closes the modal and then scrolls to the section that was clicked
  const ScrollToIndex = (index: string) => {
    setOpen(false);
    const letterIndex = document.getElementById(`search-${index}`).offsetTop;
    setTimeout(() => {
      window.scrollTo({ top: letterIndex, behavior: 'smooth' });
    }, 400);
  };

  const searchableIndexes: string[] = [];
  const buttons = [];
  options.forEach((o, i) => {
    if (searchable) {
      const firstLetter = o.label.substring(0, 1).toUpperCase();
      if (!searchableIndexes.includes(o.label.substring(0, 1))) {
        searchableIndexes.push(firstLetter);
        buttons.push(
          <SearchNavigationButton
            key={i}
            id={`search-${firstLetter}`}
            onClick={() => setOpen(true)}
            aria-label={firstLetter}
          >
            {firstLetter}
          </SearchNavigationButton>,
        );
      }
    }
    if (!hideOptions.includes(i)) {
      buttons.push(
        <BrancherToggleButtonBase
          value={o.value}
          label={o.label}
          exclusive={exclusive}
          disabled={o.disabled}
          aria-label={o.label}
          halfWidth={halfWidth}
          thirdWidth={thirdWidth}
          key={`${o.value}-${i}`}
        />,
      );
    }
  });

  const valueTexts = [];
  if (editToggle) {
    value.forEach((v) => {
      valueTexts.push(options.find((i) => i.value === v).label);
    });
  }

  const MainToggleButtons = () => (
    <ToggleButtonGroup
      value={value}
      exclusive={exclusive}
      onChange={handleChange}
      className={styles.halfWidthGroup}
      aria-label={name}
    >
      {buttons}
    </ToggleButtonGroup>
  );

  return (
    <>
      {searchable && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              {searchableIndexes.map((s, i) => (
                <Grid item key={i}>
                  <SearchLetterButton onClick={() => ScrollToIndex(s)} aria-label={s}>
                    {s}
                  </SearchLetterButton>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {editToggle ? (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={8}>
              <BrancherList listItems={valueTexts} color="purple" />
            </Grid>
            <Grid item xs={4}>
              <EditButton onClick={() => setEditOpen(true)} />
            </Grid>
          </Grid>
          <Dialog open={openEditDialog} onClose={() => setEditOpen(false)}>
            <DialogContent>
              <Text variant="xl">Edit</Text>
              <BrancherDivider marginTop={20} marginBottom={10} />
              <Text variant="sm" fontWeight={700}>
                {editToggleQuestion}
              </Text>
              {MainToggleButtons()}
            </DialogContent>
            <DialogActions>
              <BrancherButton color="primary" onClick={() => setEditOpen(false)} aria-label="Close">
                Close
              </BrancherButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        MainToggleButtons()
      )}
    </>
  );
};
