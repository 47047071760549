import { Colors } from '../consts/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = createMuiTheme().breakpoints;

export const MuiButton = (brandPrimary: string) => ({
  root: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: 'none',
    '&$disabled': {
      backgroundColor: Colors.lightGrey,
      color: Colors.white,
      border: 'none',
    },
    lineHeight: 1.2,
    boxShadow:
      '0px 2px 5px -1px rgba(0,0,0,0.07),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.07)',
    '@media (hover: none)': {
      '&.MuiButton-outlinedPrimary:hover': {
        backgroundColor: `${brandPrimary} !important`,
      },
      '&.MuiButton-outlinedSecondary:hover': {
        backgroundColor: `${Colors.white} !important`,
      },
    },
    [breakpoints.down('sm')]: {
      borderRadius: 10,
    },
    [breakpoints.up('md')]: {
      borderRadius: 20,
    },
  },
  outlinedPrimary: {
    backgroundColor: brandPrimary,
    borderColor: brandPrimary,
    color: Colors.white,
    '&:hover': {
      backgroundColor: brandPrimary,
      color: Colors.white,
      borderColor: brandPrimary,
    },
  },
  outlinedSecondary: {
    color: brandPrimary,
    borderColor: brandPrimary,
    backgroundColor: Colors.white,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: Colors.white,
      color: brandPrimary,
      borderColor: brandPrimary,
    },
    '&.Mui-disabled': {
      color: brandPrimary,
    },
  },
  outlinedSizeLarge: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 30,
    paddingRight: 30,
  },
  sizeSmall: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    '& .MuiButton-label': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  text: {
    boxShadow: 'none',
  },
  textPrimary: {
    color: brandPrimary,
    backgroundColor: Colors.transparent,
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 600,
  },
  textSecondary: {
    color: Colors.white,
    backgroundColor: brandPrimary,
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 600,
  },
  label: {
    '&$disabled': {
      color: Colors.white,
    },
  },
});
