import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import {
  BrancherDispatch,
  UtilGetInsightsFeedback,
  UtilSaveInsightsFeedback,
} from '../../store/actions';
import { SurveyEmojiButton } from '../../Components/General/SurveyEmojiButton';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { BrancherCard } from '../../Components/General/BrancherCard';

export const InsightsFeedback: React.FC = () => {
  const [gettingSurveyInfo, setGettingSurveyInfo] = React.useState<boolean>(false);
  const [savingSurveyInfo, setSavingSurveyInfo] = React.useState<boolean>(false);
  const [searchedForFeedback, setSearchedForFeedback] = React.useState<boolean>(false);
  const [rating, setRating] = React.useState<number>(undefined);
  const [moreUsefulFeedback, setMoreUsefulFeedback] = React.useState<string>('');
  const [howHelpfulFeedback, setHowHelpfulFeedback] = React.useState<string>('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingSurveyInfo(true);
    BrancherDispatch(
      dispatch,
      UtilGetInsightsFeedback((res) => {
        if(res.success) {
          setRating(res.data.rating);
          setMoreUsefulFeedback(res.data.moreUsefulFeedback);
          setHowHelpfulFeedback(res.data.howHelpfulFeedback);
        }
        setSearchedForFeedback(true);
        setGettingSurveyInfo(false);
      }),
    );
  }, []);

  const ratingTextMapping: string[] = [
    'Feel Overwhelmed',
    'Feel Neutral / Unsure',
    'Feel Thoughtful / Reflective',
    'Feel Validated / Understood',
    'Feel Surprised (in a good way!)',
    'Feel Inspired'
  ];

  const saveInsightsFeedback = () => {
    setSavingSurveyInfo(true);
    BrancherDispatch(
      dispatch,
      UtilSaveInsightsFeedback(
        {
          rating,
          ratingText: ratingTextMapping[rating - 1],
          moreUsefulFeedback,
          howHelpfulFeedback,
        },
        () => {
          setSavingSurveyInfo(false);
        },
      ),
    );
  };

  const isCTADisabled = (): boolean => gettingSurveyInfo || savingSurveyInfo || rating === undefined;

  return (gettingSurveyInfo || !searchedForFeedback ? (
      <CircularProgress size={64} />
    ) : (
      <BrancherCard>
        <Grid container justify="center">
          <Box mt={5} mb={2} display="flex" width="100%">
            <Grid container item justify="center">
              <Text variant="md" marginBottom={20} marginTop={10} fontWeight={600}>
                How do you feel about the new Insights feature?
              </Text>
            </Grid>
          </Box>
          <Grid container justify="space-between" alignItems="center" item xs={10} spacing={2}>
            <Tooltip title={ratingTextMapping[0]} arrow>
              <Box>
                <SurveyEmojiButton
                  setRating={setRating}
                  ratingIndex={1}
                  selectedIndex={rating}
                  color="orange"
                >
                  &#129327;
                </SurveyEmojiButton>
              </Box>
            </Tooltip>
            <Tooltip title={ratingTextMapping[1]} arrow>

              <Box>
              <SurveyEmojiButton
                setRating={setRating}
                ratingIndex={2}
                selectedIndex={rating}
                color="backgroundLightBlue"
              >
                &#128528;
              </SurveyEmojiButton>
              </Box>
            </Tooltip>
            <Tooltip title={ratingTextMapping[2]}>
              <Box>
                <SurveyEmojiButton
                  setRating={setRating}
                  ratingIndex={3}
                  selectedIndex={rating}
                  color="lightPurple"
                >
                  &#129488;
                </SurveyEmojiButton>
              </Box>
            </Tooltip>
            <Tooltip title={ratingTextMapping[3]}>
              <Box>
                <SurveyEmojiButton
                  setRating={setRating}
                  ratingIndex={4}
                  selectedIndex={rating}
                  color="darkBlue"
                >
                  &#128522;
                </SurveyEmojiButton>
              </Box>
            </Tooltip>
            <Tooltip title={ratingTextMapping[4]}>
              <Box>
              <SurveyEmojiButton
                setRating={setRating}
                ratingIndex={5}
                selectedIndex={rating}
                color="lightGreen"
              >
                &#128562;
              </SurveyEmojiButton>
              </Box>
            </Tooltip>
            <Tooltip title={ratingTextMapping[5]}>
              <Box>
              <SurveyEmojiButton
                setRating={setRating}
                ratingIndex={6}
                selectedIndex={rating}
                color="green"
              >
                &#128525;
              </SurveyEmojiButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField
            placeholder="..."
            multiline={true}
            fullWidth
            value={moreUsefulFeedback}
            updateValue={setMoreUsefulFeedback}
            label="What would make the Insights feature more valuable or useful to you?"
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField
            placeholder="..."
            multiline={true}
            fullWidth
            value={howHelpfulFeedback}
            updateValue={setHowHelpfulFeedback}
            label="How helpful or insightful did you find the new Insights feature?"
          />
        </Grid>
        <Grid container justify="flex-end">
          <Box display="flex" mt={5}>
            <SaveButton size="large" onClick={saveInsightsFeedback} loading={savingSurveyInfo} disabled={isCTADisabled()}>
              Save Feedback
            </SaveButton>
          </Box>
        </Grid>
      </BrancherCard>
    )
  );
};
