import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowRightOutlined from '@material-ui/icons/KeyboardArrowRightOutlined';
import { Link } from 'react-router-dom';
import { Colors } from '../../consts/colors';
import { BrancherDivider } from './BrancherDivider';
import { Text } from './Text';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

export interface IHeaderTile {
  imageSrc: string;
  title: string;
  linkTo: string;
  description?: string;
}

interface IHeaderTileStyles {
  phablet: boolean;
  mobile: boolean;
}

const useStyles = makeStyles({
  tile: {
    boxShadow: `0px 5px 10px ${Colors.cardBorder}`,
    padding: (props: IHeaderTileStyles) => (props.mobile ? 22 : props.phablet ? 32 : 40),
    minWidth: (props: IHeaderTileStyles) => (props.mobile ? 300 : props.phablet ? 340 : 400),
    borderRadius: (props: IHeaderTileStyles) => (props.mobile ? 15 : props.phablet ? 20 : 25),
  },
  illustration: {
    maxWidth: (props: IHeaderTileStyles) => (props.mobile ? 80 : props.phablet ? 90 : 110),
    maxHeight: (props: IHeaderTileStyles) => (props.mobile ? 80 : props.phablet ? 90 : 110),
  },
  openIcon: {
    width: (props: IHeaderTileStyles) => (props.mobile ? 20 : props.phablet ? 24 : 30),
    height: (props: IHeaderTileStyles) => (props.mobile ? 20 : props.phablet ? 24 : 30),
    backgroundColor: (props: { primaryColor: string }) => props.primaryColor,
    borderRadius: '50%',
  },
});

export const HeaderTile: React.FC<IHeaderTile> = (props) => {
  const theme = useTheme();
  const phablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ phablet, mobile, primaryColor });
  const { imageSrc, children, description, linkTo, title } = props;
  return (
    <Paper className={styles.tile}>
      <Link to={linkTo}>
        <Grid container direction="column">
          <Grid container alignItems="flex-end" spacing={1} justify="space-between">
            <Grid item xs={4} sm={5} md={3}>
              <img src={imageSrc} alt={title} className={styles.illustration} />
            </Grid>
            <Grid item xs={7} sm={6} md={8} container direction="column">
              <Grid item container alignItems="center" justify="space-between">
                <Text variant="xl" fontWeight={600} marginRight={10}>
                  {title}
                </Text>
                <KeyboardArrowRightOutlined className={styles.openIcon} color="action" />
              </Grid>
              <Grid item>
                <BrancherDivider marginTop={5} />
              </Grid>
            </Grid>
          </Grid>
          {description && (
            <Grid item>
              <Text variant="xs" marginTop={30}>
                {description}
              </Text>
            </Grid>
          )}
          {children && <Grid item>{children}</Grid>}
        </Grid>
      </Link>
    </Paper>
  );
};
