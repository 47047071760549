import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { SaveValuesFormData } from '../../store/actions';
import { ISegment } from '../FormFieldTypes/SegmentedControl';
import { EFormStepperSections } from '../FormStepper';
import { EComponentType } from '../../store/reducers/UserInfoReducer';
import { IDraggableColumn, IDraggableItem } from '../../Components/General/DraggableSection';
import { SaveValuesFormDataV2 } from '../../store/actions/ValuesActions';
import { ISliderSegment } from '../FormFieldTypes/SliderGroup';

export const OptionsColumnId = 'options';

export enum EValues {
  SD = 'SD',
  STI = 'STI',
  HED = 'HED',
  ACH = 'ACH',
  POW = 'POW',
  SEC = 'SEC',
  TRA = 'TRA',
  CON = 'CON',
  BEN = 'BEN',
  UNI = 'UNI',
}

const ValueOriginalNames: { [key in EValues]: string } = {
  [EValues.SD]: 'Self-Direction',
  [EValues.STI]: 'Stimulation',
  [EValues.HED]: 'Hedonism',
  [EValues.ACH]: 'Achievement',
  [EValues.POW]: 'Power',
  [EValues.SEC]: 'Security',
  [EValues.TRA]: 'Tradition',
  [EValues.CON]: 'Conformity',
  [EValues.BEN]: 'Benevolence',
  [EValues.UNI]: 'Universalism',
};

export const ValuesBrancherNames: { [key in EValues]: string } = {
  [EValues.SD]: 'Autonomy',
  [EValues.STI]: 'Variety',
  [EValues.HED]: 'Fun',
  [EValues.ACH]: 'Achievement',
  [EValues.POW]: 'Influence',
  [EValues.SEC]: 'Security',
  [EValues.TRA]: 'Tradition',
  [EValues.CON]: 'Order',
  [EValues.BEN]: 'Caring',
  [EValues.UNI]: 'Globalism',
};

const ValuePhrases: { [key in EValues]: string } = {
  [EValues.SD]: 'Being free to think and act my own way',
  [EValues.STI]: 'Having new, exciting experiences',
  [EValues.HED]: 'Enjoying life and having fun',
  [EValues.ACH]: 'Demonstrating skill and competence',
  [EValues.POW]: 'Having influence and resources',
  [EValues.SEC]: 'Safeguarding security and well-being',
  [EValues.TRA]: 'Upholding traditions and customs',
  [EValues.CON]: 'Respecting rules and social norms',
  [EValues.BEN]: 'Caring for and supporting my group',
  [EValues.UNI]: 'Protecting vulnerable groups and nature',
};

export const ValueBrancherNames: { [key in EValues]: string } = {
  [EValues.SD]: 'Autonomy',
  [EValues.STI]: 'Variety',
  [EValues.HED]: 'Fun',
  [EValues.ACH]: 'Achievement',
  [EValues.POW]: 'Influence',
  [EValues.SEC]: 'Security',
  [EValues.TRA]: 'Tradition',
  [EValues.CON]: 'Order',
  [EValues.BEN]: 'Caring',
  [EValues.UNI]: 'Globalism',
};

export interface IValuesFormConfig {
  question: React.ReactNode;
  stage?: string; // Should this also give extra info for v2? Probably not, but will leave in case
  options?: ISegment[] & IDraggableItem[] & ISliderSegment[];
  description?: string;
  section?: EFormStepperSections;
  componentType?: EComponentType;
  value?: any;
  updateValue?: (a: any) => void;
}

export const IsValuesV2 = (): boolean => {
  const config = useSelector((state: IStoreTypes) => state.user.formConfig?.values);
  return !!config?.version && config?.version === 'v2';
};

export const ValuesFormIndex = (): string[] => {
  const valuesValues = useSelector((state: IStoreTypes) => state.values.configValues) ?? [];
  if (valuesValues[0]?.key) {
    return valuesValues.map((a) => a.key);
  } else {
    return new Array(valuesValues.length).fill(0);
  }
};

export const makePrettyQuestionNumber = (unFormattedString: string): string => {
  return unFormattedString.length === 2 ? unFormattedString : `0${unFormattedString}`;
};

export function ValuesFormQuestions(): IValuesFormConfig[] {
  const values = useSelector((state: IStoreTypes) => state.values);
  const config = useSelector((state: IStoreTypes) => state.user.formConfig.values);
  const dispatch = useDispatch();

  const isValuesV2 = IsValuesV2();

  return isValuesV2
    ? config.items.map((c, index) => {
        const valuesV2 = values.response;
        const value = [
          {
            heading: 'the most important',
            placeholder: 'Most Important',
            id: 'most',
            items: valuesV2?.[index]?.most,
          },
          {
            dropAnimation: 'thinking',
            heading: '',
            id: OptionsColumnId,
            // @ts-ignore - This is a cheeky way of checking if it's the initial load or not - the default values are flat
            items: !valuesV2[index]?.options?.[0]?.id
              ? valuesV2[index]?.options?.map((f) => ({
                  id: f,
                  readableName: ValuePhrases[f],
                }))
              : valuesV2[index]?.options,
          },
          {
            heading: 'the least Important',
            placeholder: 'Least Important',
            id: 'least',
            items: valuesV2[index]?.least,
          },
        ];
        return {
          componentType: EComponentType.DND,
          question: 'Select the most important and least important to you',
          description:
            'Please select one option that is the most important to you and one option that is the least important to you',
          optionsColumn: OptionsColumnId,
          value,
          section: EFormStepperSections.VALUES,
          updateValue: (a: IDraggableColumn[]) => {
            const mostColumn = a.find((a) => a.id === 'most')?.items ?? [];
            const optionsColumn = a.find((a) => a.id === 'options')?.items ?? [];
            const leastColumn = a.find((a) => a.id === 'least')?.items ?? [];
            if (mostColumn?.length < 2 && leastColumn?.length < 2) {
              dispatch(
                SaveValuesFormDataV2({
                  most: mostColumn,
                  options: optionsColumn,
                  least: leastColumn,
                  index,
                }),
              );
            }
          },
        };
      })
    : config.map((f: any, parentIndex) => ({
        question: f.question,
        description: f.description,
        stage: `${parentIndex + 1}/${config?.length}`,
        options: f.options.map((o, i) => {
          const { startLabel, endLabel, ...other } = o;
          return {
            ...other,
            min: 0,
            max: 6,
            marks: [
              { value: 0, label: startLabel ?? `.` },
              { value: 1, label: `.` },
              { value: 2, label: `.` },
              { value: 3, label: `.` },
              { value: 4, label: `.` },
              { value: 5, label: `.` },
              { value: 6, label: endLabel ?? `.` },
            ],
            defaultValue: 3,
            step: 1,
            questionNumber: makePrettyQuestionNumber(`${parentIndex * 5 + i + 1}`),
            value: values[o.name],
            updateValue: (a: any) => dispatch(SaveValuesFormData({ [o.name]: a })),
          };
        }),
      }));
}
