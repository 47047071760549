import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from '../consts/colors';

const breakpoints = createMuiTheme().breakpoints;

export const MuiMenu = (brandPrimary: string) => ({
  paper: {
    borderRadius: 12,
    marginTop: 8,
    border: `1px solid ${brandPrimary}`,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      color: brandPrimary,
      fontSize: '15px',
      lineHeight: '18px',
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '15px',
      },
      fontWeight: 600,
      paddingLeft: 12,
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& li:hover': {
      color: Colors.white,
      background: brandPrimary,
      '& .MuiCheckbox-colorSecondary': {
        '& .MuiSvgIcon-root': {
          color: Colors.white,
        },
      },
    },
    '& li:active': {
      color: Colors.white,
      background: brandPrimary,
    },
    '& li:focus': {
      color: Colors.white,
      background: brandPrimary,
    },
    '& li.Mui-selected': {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 600,
      color: brandPrimary,
      background: Colors.white,
    },
    '& li.Mui-selected:hover': {
      color: Colors.white,
      background: brandPrimary,
      '& .MuiCheckbox-colorSecondary': {
        '& .MuiSvgIcon-root': {
          color: Colors.white,
        },
      },
    },
  },
});
