import * as React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { Text } from './Text';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  questionMarker: {
    borderRadius: '50%',
    border: (props: { primaryColor: string }) => `2px solid ${props.primaryColor}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IBrancherQuestionMarker {
  onClick: (a?: any) => void;
}

export const BrancherQuestionMarker = (props: IBrancherQuestionMarker) => {
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ primaryColor });
  const { onClick } = props;

  return (
    <Box className={styles.questionMarker} onClick={onClick} display="inline-block">
      <Text variant="xs" color="purple" display="inline" fontWeight={800} padding={8}>
        ?
      </Text>
    </Box>
  );
};
