import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EventAvailable from '@material-ui/icons/EventAvailable';
import { format, isPast } from 'date-fns';
import { Link } from 'react-router-dom';
import { Text } from '../../Components/General/Text';
import { MeetingMinutesButton, ViewButton } from '../../Components/InputFields/BrancherButton';
import { GetRoute } from '../../Components/Routing';
import { Colors } from '../../consts/colors';
import { IMeetingData, IMeetingParticipantStatus } from '../../store/reducers/MeetingsReducer';
import { MeetingMinutes } from './MeetingMinutes';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';

interface IMeetingTile {
  fullMeetingData?: IMeetingData;
  group?: boolean;
  mini?: boolean;
}

interface IMeetingTileStyles {
  allResponded: boolean;
  past: boolean;
  mini?: boolean;
  primaryColor?: string;
}

const useStyles = makeStyles({
  tile: {
    marginTop: (props: IMeetingTileStyles) => (props.mini ? 15 : 30),
    border: (props: IMeetingTileStyles) =>
      props.past
        ? 'default'
        : props.allResponded
        ? `3px solid ${Colors.green}`
        : `3px solid ${props.primaryColor}`,
    boxShadow: `0px 5px 10px ${Colors.cardBorder}`,
    borderRadius: 10,
    height: (props: IMeetingTileStyles) => (props.mini ? 75 : 112),
    paddingLeft: (props: IMeetingTileStyles) => (props.mini ? 5 : 12),
    paddingRight: (props: IMeetingTileStyles) => (props.mini ? 5 : 12),
    display: 'flex',
    background: (props: IMeetingTileStyles) => props.past && Colors.backgroundGrey,
  },
  available: {
    display: 'inline-flex',
    fontSize: '20px',
    color: Colors.green,
  },
  linkedTile: {
    display: 'inline-flex',
    // width: '100%',
  },
});

export const MeetingTile: React.FC<IMeetingTile> = (props) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [openMeetingMinutes, setOpenMeetingMinutes] = React.useState<boolean>(false);
  const { mini, group, fullMeetingData } = props;
  const { datetimeEnd, datetimeStart, participants, meetingId } = fullMeetingData;
  const allResponded: boolean = participants
    .map((p) => p.status === IMeetingParticipantStatus.ACCEPTED)
    .reduce((a, b) => a && b);
  const past = isPast(new Date(datetimeEnd));

  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ allResponded, past, mini, primaryColor });

  const meetingDate = format(new Date(datetimeStart), 'MMM do y');
  const startTime = format(new Date(datetimeStart), 'h:mm a');
  const endTime = format(new Date(datetimeEnd), 'h:mm a');

  return (
    <Paper className={styles.tile}>
      {openMeetingMinutes && (
        <MeetingMinutes
          meetingData={fullMeetingData}
          open={openMeetingMinutes}
          setClose={() => setOpenMeetingMinutes(false)}
        />
      )}
      <Grid container alignItems="center">
        {!mini && (
          <Grid item xs={mobile ? 12 : 3}>
            <Text
              variant="xs"
              fontWeight={600}
              marginRight={mobile ? 0 : 12}
              wordWrap
              align="center"
            >
              {fullMeetingData.title}
            </Text>
          </Grid>
        )}
        <Grid item container xs={12} md={mini ? 12 : 9} alignItems="center" justify="space-between">
          {mini ? (
            <LinkedMeetingTileInfo
              meetingId={meetingId}
              meetingDate={meetingDate}
              startTime={startTime}
              endTime={endTime}
              allResponded={allResponded}
              mini={mini}
            >
              <Grid item xs={mini ? 2 : 3} container justify="flex-end">
                <MeetingMinutesButton mini={mini} onClick={() => setOpenMeetingMinutes(true)} />
              </Grid>
            </LinkedMeetingTileInfo>
          ) : (
            <>
              <BaseMeetingTileInfo
                meetingDate={meetingDate}
                startTime={startTime}
                endTime={endTime}
                allResponded={allResponded}
                mini={mini}
              />
              <Grid item xs={mini ? 2 : mobile ? 5 : 3} container justify="flex-end">
                <MeetingMinutesButton mini={mini} onClick={() => setOpenMeetingMinutes(true)} />
              </Grid>
            </>
          )}
          {!mini && (
            <Grid item xs={mobile ? 12 : 3} container justify="flex-end" alignItems="center">
              {allResponded ? (
                <Grid container alignItems="center" justify="flex-end">
                  <EventAvailable className={styles.available} />
                  <Text variant="xs" color="green" fontWeight={600} display="inline" marginLeft={6}>
                    Scheduled
                  </Text>
                </Grid>
              ) : (
                !group && (
                  <Grid container item justify="flex-end">
                    <Link to={`${GetRoute('meeting').path}?m=${meetingId}`}>
                      <ViewButton size={mobile ? 'small' : 'medium'}>View</ViewButton>
                    </Link>
                  </Grid>
                )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

interface IBaseMeetingTileInfo {
  meetingDate: string;
  startTime: string;
  endTime: string;
  allResponded: boolean;
  mini: boolean;
  meetingId?: string;
}

const BaseMeetingTileInfo: React.FC<IBaseMeetingTileInfo> = ({
  meetingDate,
  endTime,
  startTime,
  allResponded,
  mini,
}) => {
  return (
    <>
      <Grid item xs={mini ? 6 : 3} md={mini ? 6 : 2}>
        <Text variant="xs" color={allResponded ? 'green' : 'purple'} fontWeight={600} wordWrap>
          {meetingDate}
        </Text>
      </Grid>
      {mini ? (
        <Grid item xs={6}>
          <Text variant="xs" color={allResponded ? 'green' : 'purple'} fontWeight={600}>
            {startTime} -{' '}
          </Text>
          <Text variant="xs" color={allResponded ? 'green' : 'purple'} fontWeight={600}>
            {endTime}
          </Text>
        </Grid>
      ) : (
        <Grid item xs={3}>
          <Text
            variant="xs"
            color={allResponded ? 'green' : 'purple'}
            fontWeight={600}
            marginLeft={6}
            marginRight={-12}
            wordWrap
          >
            {startTime} - {endTime}
          </Text>
        </Grid>
      )}
    </>
  );
};

const LinkedMeetingTileInfo: React.FC<IBaseMeetingTileInfo> = ({
  meetingId,
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Grid item container alignItems="center" justify="space-between">
      <Grid item xs={10}>
        <Link to={`${GetRoute('meeting').path}?m=${meetingId}`} className={styles.linkedTile}>
          <BaseMeetingTileInfo {...props} />
        </Link>
      </Grid>
      <Grid item container justify="flex-end" xs={2}>
        {children}
      </Grid>
    </Grid>
  );
};
