import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Edit from '@material-ui/icons/Edit';
import Notifications from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import { Link } from 'react-router-dom';
import {
  BrancherDispatch,
  SaveUserInfo,
  UtilGetPairRoleProfile,
  UtilSignOut,
} from '../../store/actions';
import { GetRoute } from '../Routing';
import { BrancherButton } from '../InputFields/BrancherButton';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { ProfilePicture } from '../../PostMatching/Profile/ProfilePicture';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { BrancherLinkText } from './BrancherLinkText';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscription } from '../../types/SubscriptionTypes';
import { IPairInfo } from '../../store/reducers/UserInfoReducer';
import { NotificationsDrawer } from './NotificationsDrawer';

const useStyles = makeStyles({
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
});

interface IHeaderNav {
  popoverAnchor: any;
  setPopoverAnchor: (event: any) => void;
}

export const HeaderNav: React.FC<IHeaderNav> = ({ popoverAnchor, setPopoverAnchor }) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const smallMobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const styles = useStyles();
  const programId = useSelector((state: IStoreTypes) => state.user.programId);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user.sessionRoleId);
  const actionsAmount = useSelector((state: IStoreTypes) => state.user?.actions)?.filter(
    (a) => a.roleId === sessionRoleId,
  )?.length;
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const hasNumerousRoles = useSelector((state: IStoreTypes) => state.user.hasNumerousRoles);
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const isMoD = useSelector((state: IStoreTypes) => state.user.subscription).includes(
    Subscription.ADHOC_PAIRING,
  );
  const sessionPair = useSelector((state: IStoreTypes) => state.user.sessionPair?.roleId);
  const pairProfile = useSelector((state: IStoreTypes) => state.profileForm?.pairProfile);
  const programMentees = useSelector((state: IStoreTypes) => state.user?.mentees)?.filter(
    (m) => m.programId === programId,
  );
  const programMentors = useSelector((state: IStoreTypes) => state.user?.mentors)?.filter(
    (m) => m.programId === programId,
  );
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const isMentor = sessionPosition === ProgramPositions.mentor;
  const dispatch = useDispatch();

  const open = Boolean(popoverAnchor);

  React.useEffect(() => {
    if (sessionPosition) {
      // tslint:disable-next-line:no-empty
      BrancherDispatch(
        dispatch,
        UtilGetPairRoleProfile(() => {}),
      );
    }
  }, [sessionPair]);

  const otherMentees = programMentees?.filter((p) => p.roleId !== pairProfile?.roleId);
  const otherMentors = programMentors?.filter((p) => p.roleId !== pairProfile?.roleId);

  const controlValidator = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const setNewSessionPair = (newPair: IPairInfo) => {
    dispatch(SaveUserInfo({ sessionPair: newPair }));
  };

  const setEditForm = () => {
    dispatch(SaveUserInfo({ editApplicationForm: true }));
  };

  const partnerRolePosition = isMentor
    ? roleLabels[ProgramPositions.mentee]
    : roleLabels[ProgramPositions.mentor];

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      justify={smallMobile ? 'space-between' : 'flex-end'}
      alignItems="center"
    >
      <Grid item>
        <NotificationsDrawer open={openDrawer} setDrawerClose={() => setOpenDrawer(false)} />
      </Grid>
      {mobile && (
        <Grid item xs={smallMobile ? 2 : 'auto'}>
          <IconButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} aria-label="logout">
            <ExitToApp />
          </IconButton>
        </Grid>
      )}
      {hasNumerousRoles && (
        <Grid item xs={smallMobile ? 3 : 'auto'}>
          <Tooltip
            title={`Switch between your ${roleLabels[ProgramPositions.mentee]} and ${
              roleLabels[ProgramPositions.mentor]
            } profiles`}
          >
            <Box>
              <Link to={GetRoute('chooseRole').path}>
                <IconButton aria-label="switch-role">
                  <TransferWithinAStation />
                </IconButton>
              </Link>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isMoD && !hasNumerousRoles && (
        <Grid item xs={smallMobile ? 2 : 'auto'}>
          <Tooltip
            title={
              isMentor
                ? `Apply to be a ${roleLabels[ProgramPositions.mentee]}`
                : `Apply to be a ${roleLabels[ProgramPositions.mentor]}`
            }
          >
            <Link to={GetRoute('qualifying').path}>
              <IconButton aria-label="qualifying">
                <LibraryAdd />
              </IconButton>
            </Link>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip title="Notifications and Tasks">
          <IconButton aria-label="notifications" onClick={() => setOpenDrawer(true)}>
            <Badge badgeContent={actionsAmount} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={smallMobile ? 2 : 'auto'}>
        <Tooltip title={`Update your ${roleLabels[sessionPosition]} application`}>
          <Link to={GetRoute('qualifying').path} onClick={setEditForm}>
            <IconButton aria-label="edit-application">
              <Edit />
            </IconButton>
          </Link>
        </Tooltip>
      </Grid>
      <Grid item xs={smallMobile ? 2 : 'auto'}>
        <Tooltip title="Profile Settings">
          <Link to={GetRoute('settings').path}>
            <IconButton aria-label="settings">
              <AccountCircle />
            </IconButton>
          </Link>
        </Tooltip>
      </Grid>
      {isMentor && programMentees?.length > 1 ? (
        <Grid item className={styles.alignSelfCenter} xs={smallMobile ? 4 : 'auto'}>
          <Grid container alignItems="center">
            {!smallMobile && (
              <ProfilePicture
                mini
                src={pairProfile.image}
                alt={`${pairProfile[EProfileAttributes.FIRST_NAME]} - your ${
                  roleLabels[ProgramPositions.mentee]
                }`}
              />
            )}
            <Grid item>
              <Tooltip title={`Progress with your other ${partnerRolePosition}s`}>
                <Box>
                  <BrancherLinkText
                    onClick={controlValidator}
                    display="inline"
                    marginLeft={5}
                    variant="xxs"
                    color="purple"
                    fontWeight={600}
                    aria-label={`switch-selected-${roleLabels[ProgramPositions.mentee]}`}
                  >
                    Switch {roleLabels[ProgramPositions.mentee]}
                  </BrancherLinkText>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          <Popover
            open={open}
            className={styles.popover}
            id={`switch-${roleLabels[ProgramPositions.mentee]}`}
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid container direction="column">
              {otherMentees?.map((o) => (
                <Grid item xs={12} key={o.name}>
                  <BrancherButton
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={() => setNewSessionPair(o)}
                  >
                    {o.name}
                  </BrancherButton>
                </Grid>
              ))}
            </Grid>
          </Popover>
        </Grid>
      ) : (
        sessionPosition === ProgramPositions.mentee &&
        programMentors?.length > 1 && (
          <Grid item className={styles.alignSelfCenter} xs={smallMobile ? 4 : 'auto'}>
            <Grid container alignItems="center">
              {!smallMobile && (
                <ProfilePicture
                  mini
                  src={pairProfile.image}
                  alt={`${pairProfile[EProfileAttributes.FIRST_NAME]} - your ${
                    roleLabels[ProgramPositions.mentor]
                  }`}
                />
              )}
              <Tooltip title={`Progress with your other ${partnerRolePosition}s`}>
                <Box>
                  <BrancherLinkText
                    onClick={controlValidator}
                    display="inline"
                    marginLeft={5}
                    variant="xxs"
                    color="purple"
                    fontWeight={600}
                    aria-label={`switch-selected-${roleLabels[ProgramPositions.mentor]}`}
                  >
                    Switch {roleLabels[ProgramPositions.mentor]}
                  </BrancherLinkText>
                </Box>
              </Tooltip>
            </Grid>
            <Popover
              open={open}
              className={styles.popover}
              id={`switch-${roleLabels[ProgramPositions.mentor]}`}
              anchorEl={popoverAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container direction="column">
                {otherMentors?.map((o) => (
                  <Grid item xs={12} key={o.name}>
                    <BrancherButton
                      size="small"
                      color="primary"
                      variant="text"
                      onClick={() => setNewSessionPair(o)}
                    >
                      {o.name}
                    </BrancherButton>
                  </Grid>
                ))}
              </Grid>
            </Popover>
          </Grid>
        )
      )}
    </Grid>
  );
};
