import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Done from '@material-ui/icons/Done';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Colors } from '../../consts/colors';
import { Text } from '../../Components/General/Text';
import { GetRoute } from '../../Components/Routing';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  card: {
    marginBottom: 50,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 165 : 260),
    border: (props: {
      active: boolean;
      completed: boolean;
      overdue: boolean;
      primaryColor: string;
    }) =>
      !props.active
        ? `0px`
        : props.overdue && !props.completed
        ? `2px solid ${Colors.red}`
        : props.active && !props.completed
        ? `2px solid ${props.primaryColor}`
        : `0px`,
    borderRadius: 15,
  },
  content: {
    padding: (props: { mobile: boolean }) => (props.mobile ? '20px 0 0 20px' : '30px 0 0 40px'),
  },
  doneIcon: {
    color: Colors.green,
  },
  button: {
    cursor: (props: { active: boolean }) => (props.active ? 'pointer' : 'default'),
    background: (props: {
      active: boolean;
      completed: boolean;
      overdue: boolean;
      primaryColor: string;
    }) =>
      !props.active && !props.completed
        ? Colors.disabledGrey
        : props.completed
        ? Colors.green
        : props.overdue
        ? Colors.red
        : props.primaryColor,
    borderTop: (props: {
      active: boolean;
      completed: boolean;
      overdue: boolean;
      primaryColor: string;
    }) =>
      !props.active
        ? `2px solid ${Colors.disabledGrey}`
        : props.overdue && !props.completed
        ? `2px solid ${Colors.red}`
        : props.active && !props.completed
        ? `2px solid ${props.primaryColor}`
        : `2px solid ${Colors.green}`,
    borderLeft: (props: {
      active: boolean;
      completed: boolean;
      overdue: boolean;
      primaryColor: string;
    }) =>
      !props.active
        ? `2px solid ${Colors.disabledGrey}`
        : props.overdue && !props.completed
        ? `2px solid ${Colors.red}`
        : props.active && !props.completed
        ? `2px solid ${props.primaryColor}`
        : `2px solid ${Colors.green}`,
    borderRadius: '20px 0 12px',
    width: '100%',
    maxWidth: (props: { mobile: boolean }) => (props.mobile ? 320 : '100%'),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: (props: { active: boolean; completed: boolean }) => (!props.active ? 0 : -1),
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 50 : 80),
  },
  moduleBottom: {
    minHeight: (props: { mobile: boolean }) => (props.mobile ? 50 : 80),
  },
  buttonInner: {
    flexWrap: 'inherit',
  },
  image: {
    width: (props: { mobile: boolean; phablet: boolean }) =>
      props.mobile ? 70 : props.phablet ? 120 : 145,
    height: (props: { mobile: boolean; phablet: boolean }) =>
      props.mobile ? 70 : props.phablet ? 120 : 145,
    margin: (props: { mobile: boolean; phablet: boolean }) =>
      props.mobile ? 15 : props.phablet ? 40 : `50px 40px`,
  },
});

export interface ITrainingModuleButton {
  buttonText: string;
  image?: string;
  heading?: string;
  subHeadingText?: string;
  description?: string;
  deadline?: string;
  deadlineYear?: string;
  completed?: boolean;
  customModule?: boolean;
  overdue?: boolean;
  active?: boolean;
  route?: string;
  name?: string;
  trainingModule?: boolean;
  alwaysOpen?: boolean;
  completeOnEndDate?: boolean;
  hasAdhocPairing?: boolean;
}

export const TrainingModuleButton = (props: ITrainingModuleButton) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const phablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const {
    completed: proxyCompleted,
    active: proxyActive,
    image,
    buttonText,
    heading,
    description,
    deadline,
    deadlineYear,
    route,
    overdue: proxyOverdue,
    alwaysOpen,
    completeOnEndDate,
    customModule,
    name,
    hasAdhocPairing,
  } = props;
  const completed = completeOnEndDate ? proxyOverdue : proxyCompleted;
  const active = alwaysOpen ? true : proxyActive;
  const overdue = completeOnEndDate ? false : proxyOverdue;
  const primaryColor = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const styles = useStyles({ mobile, active, phablet, completed, overdue, primaryColor });

  return route ? (
    <Grid container className={styles.card} justify="space-between">
      {image && (
        <Grid item xs={3} container alignItems="center">
          <Grid item>
            <img
              src={GetIllustrationLink(image)}
              alt={`${heading} Illustration`}
              className={styles.image}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container xs={image ? 9 : 12} className={styles.content}>
        <Grid item xs={10}>
          <Text variant="lg" fontWeight={700}>
            {heading}
          </Text>
        </Grid>
        <Grid item xs={10}>
          <Text
            variant="xs"
            color="secondaryGrey"
            fontWeight={600}
            marginTop={10}
            marginBottom={mobile ? 15 : 20}
          >
            {description}
          </Text>
        </Grid>
        <Grid item container alignItems="flex-end">
          <Grid
            item
            xs={6}
            container
            justify="flex-start"
            alignItems="center"
            className={styles.moduleBottom}
          >
            {hasAdhocPairing && !completed ? (
              <Text
                variant="xs"
                color={overdue ? 'red' : 'purple'}
                noPreWrap
                marginBottom={10}
                fontWeight={600}
              >
                Complete now!
              </Text>
            ) : !completed ? (
              <Text
                variant="xs"
                color={overdue ? 'red' : 'purple'}
                noPreWrap
                marginBottom={10}
                fontWeight={600}
              >
                {deadline}
                <br />
                {deadlineYear}
              </Text>
            ) : (
              <>
                <Text variant="xs" display="inline" color="green" fontWeight={600} marginRight={10}>
                  Completed
                </Text>
                <Done className={styles.doneIcon} />
              </>
            )}
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Link
              to={
                !active
                  ? GetRoute('training').path
                  : customModule
                  ? `/training/${name}`
                  : GetRoute(route).path
              }
              className={styles.button}
            >
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                className={styles.buttonInner}
                xs={8}
                md={10}
              >
                <Text variant="sm" color="white" fontWeight={500} marginLeft={20} align="center">
                  {completed ? 'Revisit' : overdue ? 'Overdue' : buttonText}
                </Text>
                <ChevronRight color="action" fontSize={mobile ? 'default' : 'large'} />
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};
