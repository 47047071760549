import { MuiAlert } from './MuiAlert';
import { MuiAppBar } from './MuiAppBar';
import { MuiBackdrop } from './MuiBackdrop';
import { MuiBadge } from './MuiBadge';
import { MuiButton } from './MuiButton';
import { MuiCard } from './MuiCard';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiCircularProgress } from './MuiCircularProgress';
import { MuiDialog } from './MuiDialog';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDrawer } from './MuiDrawer';
import { MuiExpansionPanel } from './MuiExpansionPanel';
import { MuiFab } from './MuiFab';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiLink } from './MuiLink';
import { MuiMenu } from './MuiMenu';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiPopover } from './MuiPopover';
import { MuiSelect } from './MuiSelect';
import { MuiSlider } from './MuiSlider';
import { MuiSnackbar } from './MuiSnackbar';
import { MuiSnackbarContent } from './MuiSnackbarContent';
import { MuiSpeedDial } from './MuiSpeedDial';
import { MuiStepper } from './MuiStepper';
import { MuiStepContent } from './MuiStepContent';
import { MuiStepLabel } from './MuiStepLabel';
import { MuiSvgIcon } from './MuiSvgIcon';
import { MuiSwitch } from './MuiSwitch';
import { MuiTab } from './MuiTab';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup';
import {
  MuiTypography,
  figtreeFontBold,
  figtreeFontExtraBold,
  figtreeFontMedium,
  figtreeFontRegular,
  figtreeFontSemiBold,
} from './MuiTypography';
import { Colors } from '../consts/colors';

export const BrancherTheme: any = (brandPrimaryColor: string) => ({
  overrides: {
    MuiAlert,
    MuiAppBar: MuiAppBar(brandPrimaryColor),
    MuiBackdrop,
    MuiBadge: MuiBadge(brandPrimaryColor),
    MuiButton: MuiButton(brandPrimaryColor),
    MuiCard,
    MuiCheckbox: MuiCheckbox(brandPrimaryColor),
    MuiCircularProgress: MuiCircularProgress(brandPrimaryColor),
    MuiCssBaseline: {
      '@global': {
        html: {
          overflowX: 'hidden',
        },
        body: {
          padding: `0 !important`,
          margin: 0,
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          '&::-webkit-scrollbar': {
            width: 8,
          },
          '&::-webkit-scrollbar-track': {
            background: Colors.backgroundDarkPurple,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: brandPrimaryColor /* color of the scroll thumb */,
            borderRadius: 10 /* roundness of the scroll thumb */,
            border: `3px solid transparent` /* creates padding around scroll thumb */,
          },
        },
        a: {
          textDecoration: 'none',
          color: Colors.white,
        },
        '*:focus': {
          outline: 'none !important',
        },
        '@font-face': [
          figtreeFontRegular,
          figtreeFontMedium,
          figtreeFontSemiBold,
          figtreeFontBold,
          figtreeFontExtraBold,
        ],
      },
    },
    MuiDialog: MuiDialog(brandPrimaryColor),
    MuiDialogContent: MuiDialogContent(brandPrimaryColor),
    MuiDrawer,
    MuiExpansionPanel: MuiExpansionPanel(brandPrimaryColor),
    MuiFab,
    MuiFormHelperText,
    MuiLinearProgress: MuiLinearProgress(brandPrimaryColor),
    MuiLink: MuiLink(brandPrimaryColor),
    MuiMenu: MuiMenu(brandPrimaryColor),
    MuiOutlinedInput,
    MuiPopover: MuiPopover(brandPrimaryColor),
    MuiSelect: MuiSelect(brandPrimaryColor),
    MuiSlider: MuiSlider(brandPrimaryColor),
    MuiSnackbar,
    MuiSnackbarContent: MuiSnackbarContent(brandPrimaryColor),
    MuiSpeedDial,
    MuiStepContent,
    MuiStepLabel: MuiStepLabel(brandPrimaryColor),
    MuiStepper,
    MuiSvgIcon: MuiSvgIcon(brandPrimaryColor),
    MuiSwitch: MuiSwitch(brandPrimaryColor),
    MuiTabs: MuiTabs(brandPrimaryColor),
    MuiTab: MuiTab(brandPrimaryColor),
    MuiTextField: MuiTextField(brandPrimaryColor),
    MuiToggleButton: MuiToggleButton(brandPrimaryColor),
    MuiToggleButtonGroup,
  },
  typography: MuiTypography,
});
